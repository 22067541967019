import { Box, Chip, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useAuthContext } from '../auth/AuthContext';
import ConfirmOptoutDialog from '../components/auth/ConfrimOptoutDialog';
import { useAlert } from '../context/AlertContext';

function UserProfile(props) {
  const { currentUser, loading } = useAuthContext();
  //const [jamaspUser, setJamaspUser] = useState(null);
  //const [fitbitProfile, setFitbitProfile] = React.useState({});
  const { addAlert} = useAlert();
  // User devices
  const [userDevices, setUserDevices] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  useEffect(() => {
    console.log('UserProfile useEffect user', currentUser, loading)
    if (currentUser && !loading) {
      setIsLoading(true);
      // fetchFitBitUser(user.uid).then((usr) => {
      //   let jUser = usr[0];
      //   //console.log('UserProfile fetchFitBitUser', jUser , jUser.fitbitData);
      //   setJamaspUser(jUser);
      //   try {
      //     axios.get('https://api.fitbit.com/1/user/-/profile.json', {
      //       headers: {
      //         Authorization: `Bearer ${jUser.fitbitData.access_token}`
      //       }
      //     }).then((result) => {
      //       if (result.status === 200 && result?.data?.user) {
      //         console.log(result.data.user);
      //         // update the user profile
      //         setFitbitProfile(result.data.user);
      //         axios.get('https://api.fitbit.com/1/user/-/devices.json', {
      //           headers: {
      //             Authorization: `Bearer ${jUser.fitbitData.access_token}`
      //           }
      //         }).then((result) => {
      //           if (result.status === 200 && result?.data) {
      //             console.log(result.data);
      //             // update the user profile
      //             setUserDevices(result.data);
      //           }
      //         }).catch((error) => {
      //           console.log(error);
      //         });
      //       }
      //     }).catch((error) => {
      //       console.log(error);
      //     }
      //     );
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }).catch((error) => {
      //   console.log(error);
      //   //setAlert({ type: 'error', message: error.message });
      // });
      setIsLoading(false);
    }
  }, [currentUser, loading]);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDialogOutput = (value) => {
    console.log("handleDialogClose", value);
    //setAlert(null);
    if (value) {
      // TODO: Opt out of research
    }
    setDialogOpen(false);
  };

  return (
    <Box sx={{ mt: 5, m: 1 }}>
      {loading && (
        // TODO: Create a loading component, use it here and in other places
        <Typography>Loading...</Typography>
      )}
      {!loading && (
        <>
          <Typography variant='h4'>User Profile</Typography>
          <Typography variant='body1' component='div'>
            Email: <Chip label={currentUser.email}></Chip>
          </Typography>
          {isLoading && (
            <Typography variant='body1' component='div'>
              Loading...
            </Typography>
          )}
          {/* <ConfirmOptoutDialog
            withButton={true}
            buttonText="Opt out of research"
            buttonColor="error"
            handleClose={handleConfirmDialogOutput}
          /> */}

        </>
      )}
    </Box>

  );

}
export default UserProfile;