import 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { downloadSensors } from '../data/sensorsDownload';
//import { log } from 'firebase-functions/logger';

// Function to get all sensors id
const getAllSensorsId = () => {
  const sensorsId = downloadSensors.map(sensor => sensor.id);
  return sensorsId;
};

/**
 * Formats a date string or object to "yyyy-MM-dd" format.
 * If the input is already in "yyyy-MM-dd" format, it returns the input as is.
 * If the input is not in "yyyy-MM-dd" format, it tries to convert it to a Date object and formats it as "yyyy-MM-dd".
 * If the input is not a string or a Date object, it returns the input as is.
 *
 * @param {string|Date} date - The date to be formatted.
 * @return {string} The formatted date in "yyyy-MM-dd" format.
 */
const formatDate = (date) => {
  let newDate ;
  if(typeof date === 'string'){
    // check if the date as a string in the format "yyyy-MM-dd"
    if(date.match(/^\d{4}-\d{2}-\d{2}$/) !== null){
      return date;
    }else{
      // if the date is not in the format "yyyy-MM-dd"
      // convert it to a Date object and the rest will format it as "yyyy-MM-dd"
      try {
        newDate = new Date(date);
      } catch (err) { // if we couldn't convert the date we will use today's date //TDOO: check for a better approach
        console.log("formatDate: ", err);
        newDate = new Date();
      }
    }
  }else{
    newDate = date;
  }
  const formatter = new Intl.DateTimeFormat('en-NY', { year: 'numeric', month: '2-digit', day: '2-digit' });
  let formattedDate = formatter.format(newDate);
  // Split the date parts
  let dateParts = formattedDate.split('/');
  // Rearrange the date parts to "yyyy-MM-dd" format
  formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  return formattedDate;
};

/**
 * Converts a given date to a Firestore Timestamp.
 *
 * @param {Date} date - The date to convert.
 * @returns {Timestamp} - The Firestore Timestamp.
 */
const getTimestampFromDate = (date) => {
  console.log("getTimeFromDate : %o , type : %s", date, typeof date);
  let date_time;
  try{
    date_time = Timestamp.fromDate(new Date(date));
  }catch(err){
    console.log("getTimeFromDate: error: Timestamp.fromDate(new Date(date)) : ", err);
    try{
      date_time = Timestamp.fromDate(date);
    }catch(err){
      console.log("getTimeFromDate: error : Timestamp.fromDate(date) : ", err);
    }
    date_time = Timestamp.fromDate(new Date());
  }
  return date_time;
};

/**
 * Converts the input date to a formatted date string.
 * If the input date is "today", it returns the current date.
 * If the input date is in the format "DD-MM-YYYY", it converts it to a Date object and returns the formatted date string.
 * If the input date is invalid or cannot be converted, it returns the current date.
 *
 * @param {string} inputDate - The input date to be converted.
 * @returns {string|null} The formatted date string or null if the input date is invalid.
 */
const convertDefaultDate = (inputDate) => {
  console.log("convertDefaultDate", inputDate);
  if (!inputDate)
    return null;

  if (typeof inputDate === 'string') {
    // Check if input date is today or a date string DD-MM-YYYY format
    if (inputDate === "today") {
      console.log("today");
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const formattedDate = formatDate(today);
      console.log("formattedDate", formattedDate);
      return formattedDate;
    }
    else {
      try {
        const date = Date?.parse(inputDate);
        if ( !(!!date && date instanceof Date) ) {
          //log("Invalid date format", inputDate);
          //TODO: return null or throw error or handle error; now we make it return current date
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return formatDate(today);
        }
      } catch (err) {
        //log("Some error happened while trying to convert: %o , %o", err, inputDate);
        //TODO: Idk what should I do here, it seems inputDate couldn't be converted to date, I can not mind it and return it, or, like now I will return current date
          //TODO: return null or throw error or handle error; now we make it return current date
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return formatDate(today);
      }
    }
  }
  // it means inputDate is not a string, but what is it? I don't know, so I will return current date
  return inputDate;
};

const convertDateToReadableString = function(date) {
  if(!!date && date instanceof Date){
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "America/New_York",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date).toString();
  }else{ // We can also check if the date is a string and try to convert it to a Date object and format it
    return date;
  }

}

// Export functions
export { convertDefaultDate, formatDate, getAllSensorsId, getTimestampFromDate, convertDateToReadableString };

