import { CloudSyncSharp, ContactSupport, InsightsSharp, ThumbUp as ThumbUpIcon } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Paper, Stack, Typography, styled } from '@mui/material';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import { getOrRenewAccessToken } from '../auth/FitbitAuthUtils';
import ConfirmOptoutDialog from '../components/auth/ConfrimOptoutDialog';
import { useAlert } from '../context/AlertContext';
import { auth, db, functions } from '../firebase';


const Dashboard = () => {

  //const [fitbitToken, setFitbitToken] = useState(null);
  const { loading, currentUser, userJamasp } = useAuthContext();
  const { addAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [stepsData, setStepsData] = useState(null);
  const [fitbitToken, setFitbitToken] = useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { navigate } = useNavigate();

  // const logout = () => {
  //   signOut(auth).then(() => {
  //     // Sign-out successful.
  //   }).catch((error) => {
  //     // An error happened.
  //   });
  // }

  const DashboardItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 248,
    maxWidth: '20vw',
  }));

  /**
   * Generate data for the chart at load time
   */
  useEffect(() => {
    setStepsData([
      ["Date", "Steps"],
      [moment().format('DD-MM-YYYY'), 10],
      [moment().subtract(1, 'days').format('DD-MM-YYYY'), 4000],
      [moment().subtract(2, 'days').format('DD-MM-YYYY'), 2340],
      [moment().subtract(3, 'days').format('DD-MM-YYYY'), 30210],
    ]);
  }, []);

  // This is a demonstration for requesting data scopes
  // Code for fetching should be placed in a separate file.
  useEffect(() => {
    if (loading) {  // if auth state is loading
      // maybe trigger a loading screen
      return;
    }

    if (userJamasp?.fitbitData?.access_token) {
      addAlert("success", "Welcome " + userJamasp.uid);
      setFitbitToken(userJamasp.fitbitData);
      try {
        const newToken = getOrRenewAccessToken('renew', userJamasp.fitbitData.refresh_token, '').catch(error => {
          console.log(error);
        });
        console.log("newToken : %o , oldToken : %o", newToken, userJamasp?.fitbitData);
        // check if a new token was returned
        let fitbitToken = newToken?.access_token?.length > 0 ? newToken : userJamasp?.fitbitData;

        if (fitbitToken?.access_token?.length > 0) {
          setFitbitToken(fitbitToken);
          // TODO: Save the new token to the database
          const startDate = moment().subtract(30, 'days').format('DD-MM-YYYY');
          const endDate = moment().format('DD-MM-YYYY');

          let url = 'https://api.fitbit.com/1/user/' + userJamasp?.fitbitData?.user_id +
            '/activities/steps/date/' + startDate + '/' + endDate + '.json';
          //url = encodeURIComponent(url);
          console.log(url);
          console.log(encodeURIComponent(url));

          const serviceBaseUrl = 'https://fetch-download-job-bsa7yb7sga-uc.a.run.app/fetchSensorData/?'
          const argumentsPart = 'sensor_id=Get_Activity_steps_Time_Series_by_Date_Range&api_key=' +
            userJamasp?.fitbitData?.access_token + '&api_url=' + url;
          const endpointUrl = serviceBaseUrl + argumentsPart;
          console.log(endpointUrl);
          axios.get(endpointUrl).then(response => {
            console.log(response);
            addAlert('success', 'Data fetched successfully');
          }).catch(error => {
            console.log(error);
          })
        } else {
          addAlert("error", "Error in login!");
          console.log("access token is null");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      addAlert("error", "Error in login!");
      console.log("user is null");
    }
  }, [userJamasp]); // The dependency array ensures the effect runs when the access token changes

  const handleGenerateFibitAuth = async () => {
    const generateFitbitAuthData = httpsCallable(functions, 'generateVerifierAndChallenge');
    generateFitbitAuthData({ invitationId: userJamasp.invitationId }).then((result) => {
      console.log(result);
      if (result?.data?.redirectUri) {
        window.location.href = result?.data?.redirectUri;
      } else {
        console.log("Error in generating verifier and challenge");
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const handleOptOutModalOutput = async (value) => {
    if (value) {
      const outOutOfResearchCall = httpsCallable(functions, 'optOut');
      outOutOfResearchCall({ userId: userJamasp.uid }).then((result) => {
        console.log(result);
        if (result?.data?.success) {
          addAlert('success', 'You have been opted out of the research');
        } else {
          addAlert('error', 'Error in opting out of the research');
        }
      }).catch((error) => {
        addAlert('error', 'Error in opting out of the research' + error?.message);
        console.log(error);
      });
    } else {
      setDialogOpen(false);
    }
  };

  const unregisterGoogle = async () => {
    const userRef = doc(db, 'users', userJamasp.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      signOut(auth).then(() => {
        console.log("User signed out");
        db.collection('users').doc(userJamasp.uid).update({
          ...userDoc.data(),
          enabled: false
        }).then(() => {
          console.log("User disabled");
          addAlert('success', 'You have been disabled');
          navigate('/');
        }).catch((error) => {
          console.log(error);
        });
      }).catch((error) => {
        console.log(error);
      });
    };
    return Promise.resolve(true);
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 1 }} align="center">
      <Typography variant="h5" gutterBottom>User Dashboard</Typography>
      {/* <Button onClick={() => logout()}>Sign Out</Button> */}
      {/* {fitbitToken === null && <div>You should authenticate with fitbit</div>} */}
      {/* {fitbitToken === null && <AuthenticationBtn />} */}
      {/* {fitbitToken !== null && */}
      {(loading || isLoading) && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading || isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Paper elevation={3} sx={{ overflow: 'hidden', opacity: 0.9, m: 3, p: 3 }} >
        {!!fitbitToken ?
          (
            <React.Fragment>
              <Typography variant="h6" color="initial" >
                You are authenticated with user id of : {userJamasp?.uid}
              </Typography>
              <Typography variant="body1" color="initial">
                Thank you for joining our experiment and completing the steps. Your fitbit user id is {userJamasp?.fitbitData?.user_id}
              </Typography>
            </React.Fragment>
          )
          :
          (
            <React.Fragment>
              <Typography variant="h6" color="error" >
                You are not authenticated with fitbit, Please authenticate with fitbit to continue.
              </Typography>
              <Button variant="contained" onClick={() => handleGenerateFibitAuth()}>Register Fitbit</Button>
            </React.Fragment>
          )
        }
      </Paper>

      {!!fitbitToken ?
        (
          <Box sx={{ m: 3, mb: 6, display: 'flex', flexGrow: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={3}>
                <DashboardItem>
                  <Typography variant="body1" color="initial">
                    You are good to go.
                  </Typography>
                  <ThumbUpIcon color='success' sx={{ fontSize: '100px', bottom: 0, alignSelf: 'center' }} />
                  <Stack direction="column" spacing={2} align="end">
                    <Button size='small' variant='contained' color='warning' onClick={() => unregisterGoogle()}>Unregister Google</Button>
                    <ConfirmOptoutDialog
                      withButton={true}
                      buttonText="Opt out of research"
                      buttonColor="error"
                      handleClose={handleOptOutModalOutput}
                    />
                  </Stack>
                </DashboardItem>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DashboardItem>
                  <CloudSyncSharp sx={{ width: '100%', height: '80%' }} />
                  <Typography variant="body1" color="success">
                    Last Sync: {userJamasp?.fitbitData?.timestamp?.toDate().toDateString("dd/MM/yyyy HH:mm:ss")}
                  </Typography>
                </DashboardItem>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DashboardItem>
                  <InsightsSharp sx={{ width: '100%', height: '80%', color: (theme) => theme.palette.success.light }} />
                  <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.success.light }}>
                    Visualization & Insights
                  </Typography>
                </DashboardItem>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DashboardItem>
                  <ContactSupport sx={{ width: '100%', height: '80%' }} />
                  <Typography variant="subtitle1" color="info">
                    Contact Support
                  </Typography>
                </DashboardItem>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Chart
                  width={'500px'}
                  height={'300px'}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={stepsData}
                  options={{
                    title: 'Stpe In The Recent Months',
                    hAxis: { title: 'Date', dataType: 'datetime' },
                    vAxis: { title: 'Steps', minValue: 0, maxValue: 2000 },
                    legend: 'true',
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
              </Grid>
            </Grid>
          </Box>
        )
        :
        (<span ></span>)
      }
    </Container>
  );
} // End of Dashboard component;
export default Dashboard;
// Remove the export statement for removeUserDevice
