import { LockOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useAlert } from "../../context/AlertContext";
import { useRegistration } from "../../context/RegistrationContext";
import { useEffect, useState } from "react";

const SignUpStep = () => {

  const {currentUser, loading, inviteeData, localLoading, setLocalLoading, nextStep, error, setError
      ,updateInviteeData, saveInviteeData, handleSignUpWithGoogle } = useRegistration();

  const [email, setEmail] = useState(inviteeData?.email || '');
  const [emailError, setEmailError] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const { addAlert } = useAlert();

  useEffect(()=> {
    if(!!inviteeData?.email)
      if(inviteeData?.email !== email) {
        console.log("inviteeData.email (%s) !== email(%s) , so emailChanged!", inviteeData?.email, email);
        setEmailChanged(true);
      }else {
        setEmailChanged(false);
      }
  },[inviteeData, email]);

  const handleEmailInputChange = e => {
    if(loading || localLoading) {
      console.log("loading or localLoading, return");
      return;
    }
    setEmail(e.target.value);
    if (!e.target.validity.valid) {
      setError(e.target.validationMessage);
      setEmailError(true);
    } else {
      setError(false);
      setEmailError(false);
    }
  }

  const handleSaveEmailChange = async() => {
    if(loading || localLoading || emailError || !emailChanged) {
      console.log("loading or localLoading or emailError or !emailChanged, return");
      return;
    }
    setLocalLoading(true);
    updateInviteeData({ email: email });
    saveInviteeData({email: email});
    try {
      const res = await saveInviteeData();
      console.log("saveInviteeData res: ", res);
      if(res){
        addAlert('success','Email saved successfully!')
        setEmailChanged(false);
      }else{
        addAlert('error','Error saving email!');
      }
    } catch (err) {
      console.error("Error saving email change: ", err);
    }
    setLocalLoading(false);
  }

  return (
    <Paper variant="outlined" sx={{
      my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h4" variant="h5">
        Sign in with Google
      </Typography>
      <Box component="form" sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }} >
        <TextField
          error={emailError}
          helperText={emailError && "Please enter a valid email address"}
          inputProps={{
            type: "email",
            pattern: "^\\S+@\\S+\\.\\S+$"
          }}
          variant="outlined" 
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          placeholder="Enter your email address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleEmailInputChange}
        />
        {!emailError && !loading && !localLoading && emailChanged && (
          <Button disabled={loading || localLoading || emailError || !emailChanged} 
              onClick={handleSaveEmailChange} 
              fullWidth variant={emailChanged ? "contained" : "outlined"} color={emailChanged ? "primary" : "secondary"} 
              sx={{ mt: 3, mb: 2 }}
          >
            Change Email
          </Button>
        )}
      </Box>
      <Typography variant="subtitle2" color="text.secondary" align="justify">
        * Please sign in with the Google account you are setting: <code>{email}</code> . If it's not a google account, you can change it now!
      </Typography>
      <Button
        disabled={loading || localLoading || emailError || emailChanged}
        onClick={(e) => handleSignUpWithGoogle(email)} // Use the signInWithGoogle function from the AuthContext
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In with Google
      </Button>
    </Paper>
  );
}
export default SignUpStep;