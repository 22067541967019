import React, { Fragment, useState } from 'react';
import { auth } from "../firebase";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PhoneAuthProvider, RecaptchaVerifier, linkWithCredential } from 'firebase/auth';
import { useAlert } from '../context/AlertContext';

const recaptchaSize = 'normal';
const enabled = false;

function PhoneLogin() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [verificationId, setVerificationId] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [agreed, setAgreed] = useState(false);

    const { addAlert } = useAlert();

    const clearApplicationVerifier = () => {
        console.log("clearApplicationVerifier : %o : %o", recaptchaVerifier, window.recaptchaVerifier);
        if (recaptchaVerifier !== null) {
            recaptchaVerifier?.clear();
            setRecaptchaVerifier(null);
        }
        if (window.recaptchaVerifier !== null) window.recaptchaVerifier?.clear();
    };
    /* Initializes the ApplicationVerifier.
    * @param {string} submitButtonId The ID of the DOM element of the button to
    *     which we attach the invisible reCAPTCHA. This is required even in visible
    *     mode.
    */
    function makeApplicationVerifier(submitButtonId) {
        const container =
            recaptchaSize === 'invisible' ? submitButtonId : 'recaptcha-container';
        window.recaptchaVerifier = new RecaptchaVerifier(auth, container, {
            'size': (recaptchaSize === 'invisible' ? 'invisible' : 'normal'),
            'callback': (response) => {
                console.log("makeApplicationVerifier : %o", response);
            },
            'expired-callback': () => {
                console.log('expired-callback');
                // Response expired. Ask user to solve reCAPTCHA again.
                addAlert('error', 'Response expired. Ask user to solve reCAPTCHA again.');

            },
            'error-callback': (error) => {
                console.log(error);
            }
        }, (error) => {
            console.log(error);
            //todo: handle error
        });
        return window.recaptchaVerifier;
    }

    // const preRenderRecaptcha = () => {
    //   recaptchaVerifier.render().then((widgetId) => {
    //     window.recaptchaWidgetId = widgetId;
    //   });
    // }


    const handleSignIn = async (e) => {
        e.preventDefault();
        const provider = new PhoneAuthProvider(auth);

        clearApplicationVerifier();
        const applicationVerifier = makeApplicationVerifier('signin-verify-phone-number');
        console.log("setting applicationVerifier : %o", applicationVerifier);
        setRecaptchaVerifier(applicationVerifier)

        provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier).then(
            verificationId => {
                console.log('verificationId', verificationId);
                setVerificationId(verificationId);
                clearApplicationVerifier();
                addAlert('success', 'Phone verification sent!');
            },
            error => {
                clearApplicationVerifier();
                addAlert('error', error.toString());
            }
        ).catch(error => {
            console.log(error);
        });

    }

    /**
     * Confirms a phone number verification for sign-in.
     */
    function handleVerifyCode(e) {
        e.preventDefault();
        console.log('handleVerifyCode', verificationId, verificationCode);
        const credential = PhoneAuthProvider.credential(
            verificationId,
            verificationCode
        );
        signInOrLinkCredential(credential);
    }


    function logAdditionalUserInfo(result) {
        // Implement the logic for logging additional user info here.
        console.log('Additional user info:', result);
        if (result.isNewUser) {
            console.log('New user created');
        }

    }

    function refreshUserData() {
        // Implement the logic for refreshing user data here.
        console.log('Refreshing user data');

    }

    /**
     * Signs in or links a provider's credential, based on current tab opened.
     * @param {!AuthCredential} credential The provider's credential.
     */
    function signInOrLinkCredential(credential) {
        if (auth.currentUser === null) {
            addAlert('error', 'You need to sign in before linking an account.');
            return;
        }


        linkWithCredential(auth.currentUser, credential).then(
            result => {
                logAdditionalUserInfo(result);
                refreshUserData();
                onAuthSuccess(result.user);
                addAlert('success', 'Provider linked!');
            }, onAuthError).catch(error => {
                onAuthError(error);
            });
    }

    /**
     * Displays an error message when the authentication failed.
     * @param {!Error} error Error message to display.
     */
    function onAuthError(error) {
        //logAtLevel_(error, 'error');
        console.log(error)
        if (error.code === 'auth/multi-factor-auth-required') {
            // Handle second factor sign-in.
            //handleMultiFactorSignIn(getMultiFactorResolver(auth, error));
        } else {
            addAlert('error', 'Error: ' + error.code);
            if (error.code === 'auth/user-token-expired') {
                addAlert('error', 'Token expired, please reauthenticate.');
            }
            if (error.code === 'auth/invalid-credential') {
                addAlert('error',
                    'Login credentials invalid. It is possible that the email/password combination does not exist.'
                );
            }
        }
    }

    /**
    * Updates the UI when the user is successfully authenticated.
    * @param {!firebase.User} user User authenticated.
    */
    function onAuthSuccess(user) {
        console.log(user);
        addAlert('success', 'User authenticated, id: ' + user.uid);

        // here we should update the 
        // refreshUserData();
    }

    const handleAgreeTerms = (event) => {
        setAgreed(event.target.checked);
    }

    return enabled ?
        (
            <Box>
                <Typography variant="h5" sx={{ mt: 5, mb: 1 }} color={'warning'}>This method of authentication is has been disabled.</Typography>
            </Box>
        )
        :
        (
            <Fragment>
                <Box sx={{ mt: 5, m: 1 }}>
                    <Typography variant="h3">Phone Login</Typography>
                    <Typography variant="subtitle">Please Enter Your Phone To Complete Your Registration</Typography>
                    <Box component={"form"} onSubmit={handleSignIn} sx={{ m: 1, '& > :not(style)': { m: 1, width: '25ch' } }} >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone Number (e.g. +1 123 456 7890)"
                            name="phone"
                            autoComplete="phone"
                            pattern="[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*"
                            autoFocus
                            type='tel'
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                        />

                        <FormControlLabel
                            control={<Checkbox value="accept" required onChange={handleAgreeTerms} color="primary" />}
                            label="By clicking this box, I accept the Terms of Service and Privacy Policy and I consent to Jamasp's use of my contact information for sending me messages. Message & data rates may apply"
                        />
                        <Button
                            type="submit"
                            disabled={!agreed}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send verification code
                        </Button>
                        <br />
                        <Typography variant="subtitle">We will send you an SMS to confirm your phone number. Message & data rates may apply.</Typography>
                        <Typography variant="subtitle">By signing in, you agree to our <Link to="/terms" target='_blank'>Terms of Service and Privacy Policy</Link>.</Typography>

                    </Box>
                    {verificationId !== null && (
                        // <form onSubmit={() => handleVerifyCode(verificationCode)}>
                        <Box component={"form"} onSubmit={handleVerifyCode} sx={{ m: 1, '& > :not(style)': { m: 1, width: '25ch' } }}>
                            <TextField
                                margin="normal"
                                type="text"
                                required
                                fullWidth
                                id="verificationCode"
                                label="Verification Code"
                                name="verificationCode"
                                autoComplete="verificationCode"
                                autoFocus
                                pattern="^[0-9]{6}$"
                                onChange={(e) => setVerificationCode(e.target.value)}
                                value={verificationCode}
                            />
                            <Button id="signin-verify-phone-number" type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Verify code</Button>
                        </Box>
                    )}

                </Box>
            </Fragment>
        );
}

export default PhoneLogin;