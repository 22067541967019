import { Button, Paper, Typography } from "@mui/material";
import { useAlert } from "../../context/AlertContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../context/RegistrationContext";

const FitbitAuthenticationStep = (props) => {
  const { inviteeData, currentUser, userJamasp, loading, localLoading, setLocalLoading, nextStep, error, setError
    , updateInviteeData, saveInviteeData } = useRegistration();

  const { addAlert } = useAlert();
  const [allowFitbitAccess, setAllowFitbitAccess] = useState(false);

  const navigate = useNavigate();

  // Hacking to force a refresh at the start
  useEffect(() => {
    // window.location.reload(false);
  }, []);

  useEffect(() => {
    if (loading || localLoading) {
      return;
    }
    console.log(currentUser, userJamasp, loading);
    if (currentUser) {
      setAllowFitbitAccess(true);
    }
  }, [currentUser, localLoading, loading, userJamasp]);

  const handleGenerateFibitAuth = async () => {
    window.localStorage.setItem("invitationID", inviteeData.invitationID);
    setLocalLoading(true);
    console.log("Generating Fitbit Auth");
    const generateFitbitAuthData = httpsCallable(functions, 'generateVerifierAndChallenge');
    generateFitbitAuthData({ invitationId: inviteeData.invitationID }).then((result) => {
      console.log(result);
      if (result?.data?.redirectUri) {
        window.location.href = result?.data?.redirectUri;
      } else {
        setLocalLoading(false);
        console.log("Error in generating verifier and challenge");
        setError("Error in generating verifier and challenge");
        addAlert("error", "Error in generating verifier and challenge");
        navigate("/auth/register/" + inviteeData.invitationID);
      }
    }).catch((error) => {
      console.log(error);
      setLocalLoading(false);
      setError("Error in generating verifier and challenge");
      addAlert("error", "Error in generating verifier and challenge");
      navigate("/auth/register/" + inviteeData.invitationID);
    });
  }

  return (
    <Paper variant="outlined" sx={{
      my: { xs: 1, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Typography variant="h4" align="center" gutterBottom>
        Authenticate with Fitbit
      </Typography>
      {error && <Typography variant="body1" align="center" color="error">{error?.message || error}</Typography>}
      <Button disabled={!allowFitbitAccess || localLoading} variant="contained" onClick={() => handleGenerateFibitAuth()}>Fitbit Authentication</Button>
    </Paper>
  );
}
export default FitbitAuthenticationStep;