import { Autocomplete, Box, Button, Card, CardActions, CardContent, Collapse, FormControl, FormGroup, FormHelperText, Input, InputLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { Fragment } from 'react';
import { defaultRangeDownloadSettings, generateSensorSettings, downloadSensors as sensorsList } from '../data/sensorsDownload';
import SensorDownloadSettings from './SensorDownloadSettings';


/**
 * DownloadPanel component for managing sensor downloads.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.project - The project name.
 * @param {function} props.setSensorsName - The function to set the sensors name.
 * @param {function} props.setSensorsSettings - The function to set the sensors settings.
 * @param {Array} props.sensorsName - The array of sensors names.
 * @param {Array} props.sensorsSettings - The array of sensors settings.
 * @returns {JSX.Element} The DownloadPanel component.
 */
function DownloadPanel(props) {
  const { project, setSensorsName, setSensorsSettings, sensorsName, sensorsSettings,
    handlePrepareDownload, rangeDownloadSettings, setRangeDownloadSettings } = props;

  /**
   * 
   * @param {*} event  event.target.name = argument name
   * @param {*} sensor   sensor object like admin/utills/sensorTemplate.js
   * @param {*} settingType   argument or parameter
   */
  const handleSensorSettingsChange = (event, sensor, settingType) => {
    let { name, value } = event.target;

    //console.log("handleSensorSettingsChange",name,value);
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    let newSettings;
    let sensorConfig = sensorsSettings.find(item => item.sensorId === sensor.id);
    //console.log("found sensorConfig", sensorConfig);
    if (sensorConfig === null || sensorConfig === undefined) {
      sensorConfig = generateSensorSettings(sensor);
      if (settingType === 'argument') {
        sensorConfig.arguments[name] = value;
      } else if (settingType === 'parameter') {
        sensorConfig.parameters[name] = value;
      }
      newSettings = [...sensorsSettings, { sensorConfig }];
    } else {
      newSettings = sensorsSettings.map(item => {
        if (item.sensorId === sensor.id) {
          if (settingType === 'argument') {
            item.arguments[name] = value;
          } else if (settingType === 'parameter') {
            item.parameters[name] = value;
          }
        }
        return item;
      });
    }
    //console.log("setting new settings", sensorsSettings, newSettings);
    setSensorsSettings(newSettings);
  }

  /**
   * 
   * @param {*} event  event.target.name = argument name
   * @param {*} name  sensor name
   */
  const handleChange = (event) => {
    //console.log("handleChange", event);
    const {
      target: { value },
    } = event;
    setSensorsName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRangeDownloadSettingsToggle = (event) => {
    if (rangeDownloadSettings === null || rangeDownloadSettings === undefined) {
      setRangeDownloadSettings({ ...defaultRangeDownloadSettings, "enabled": event.target.checked });
    } else {
      setRangeDownloadSettings({ ...rangeDownloadSettings, "enabled": event.target.checked });
    }
    //setRangeDownloadEnabled(event.target.checked);
  }

  const handleRangeDownloadSettingsChange = (event, name) => {
    const { value } = event.target;
    console.log(event, name, value);
    if (rangeDownloadSettings === null || rangeDownloadSettings === undefined) {
      setRangeDownloadSettings({ ...defaultRangeDownloadSettings, [name]: value });
    } else {
      setRangeDownloadSettings({ ...rangeDownloadSettings, [name]: value });
    }
  }

  /**
   *  Render
   */
  return (
    <Box>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, mb: 2 },
        }}
        noValidate
        autoComplete="off"
      >
        <Card sx={{ p: 2, mb: 2 }}>
          <CardActions>
            <Typography>Range mode?</Typography>
            {console.log("rangeDownloadSettings?.enabled", rangeDownloadSettings)}
            <Switch
              checked={rangeDownloadSettings?.enabled ? true : false}
              onChange={handleRangeDownloadSettingsToggle}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </CardActions>
          <Collapse in={rangeDownloadSettings?.enabled}>
            <CardContent>
              <FormControl>
                <InputLabel htmlFor="rangeStartDate">Start Date</InputLabel>
                <Input id="rangeStartDate" aria-describedby="range-helper-text" type="date" value={rangeDownloadSettings?.["start-date"]} onChange={(e) => handleRangeDownloadSettingsChange(e, "start-date")} />
                <FormHelperText id="range-helper-text">From this date to end date</FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="rangeEndDate">End Date</InputLabel>
                <Input id="rangeEndDate" aria-describedby="range-end-helper-text" type="date" value={rangeDownloadSettings?.["end-date"]} onChange={(e) => handleRangeDownloadSettingsChange(e, "end-date")} />
                <FormHelperText id="range-end-helper-text">From start date to this date</FormHelperText>
              </FormControl>
            </CardContent>
          </Collapse>
        </Card>
        <Autocomplete
          multiple
          id="sensors-multiple"
          sx={{ mb: 2 }}
          options={sensorsList}
          filterSelectedOptions
          getOptionLabel={(option) => option.id}
          value={sensorsList.filter((sensor) => sensorsName?.includes(sensor.id))}
          onChange={(e, newValue) => {
            const event = {
              target: {
                value: newValue.map((item) => item.id)
              }
            };
            handleChange(event);
          }}
          renderOption={(props, option) => {
            console.log("renderOption", props, option);
            return (
              <Box component="li" key={option.id+'box'} {...props}>
                <Typography variant="body1">
                  <b>{option.label}</b> : {option.description}
                </Typography>
              </Box>
            )
          }}
          renderInput={(params) => {
            console.log("renderInput", params);
            return (
              <TextField
                {...params}
                variant="standard"
                label="Select desired sensors"
                placeholder="Sensors"
              />
            )
          }}
        />

        <FormGroup>
          {sensorsList.map((sensor) => (
            <Fragment key={'Fragment-' + sensor.id}>
              {sensorsName?.includes(sensor.id) && (
                <SensorDownloadSettings key={sensor.id}
                  sensor={sensor}
                  sensorSettings={sensorsSettings?.find((s) => s.sensorId === sensor.id)}
                  project={project}
                  handleSettingsChange={handleSensorSettingsChange}
                  handleRemoveSensor={(sensorId) => {
                    setSensorsName(sensorsName.filter((name) => name !== sensorId));
                  }}
                />
              )}

            </Fragment>
          ))}
        </FormGroup>
      </Box>
      <Stack spacing={2} direction={"row"}>
        <Button variant="contained" color="primary" onClick={handlePrepareDownload} > Start Download Job</Button>
      </Stack>
    </Box>
  )
}

export default DownloadPanel;

