import axios from 'axios';
import { setFitbitAuthData } from './AuthUtils';

const serviceBaseUrl = 'https://fetch-download-job-bsa7yb7sga-uc.a.run.app/'
const getTokenUrl = serviceBaseUrl + 'getToken'; //'http://getFitbitToken-jw3n47gu5q-uc.a.run.app';
const refershTokenUrl = serviceBaseUrl + 'refreshToken'; //'https://refreshFitbitToken-jw3n47gu5q-uc.a.run.app';
export const fitbitApiBaseUrl = 'https://api.fitbit.com';


/**
 * Retrieves or renews an access token.
 *
 * @param {string} type - The type of request ('get' or 'renew').
 * @param {string} code - The code parameter.
 * @param {string} verifier - The code verifier parameter.
 * @return {Promise<Object>} - A Promise that resolves to the token information object.
 */
async function getOrRenewAccessToken(type, code, verifier) {
	let url;
	url = ((type === 'get') ? getTokenUrl : refershTokenUrl) + '/?code=' +
		code + verifier?.length>0 ? '&code_verifier=' + verifier : '';

	// Use Axios to make a GET request to the endpoint
	const tokenInfo = await axios.get(url);
	console.log(tokenInfo?.data);
  if(!tokenInfo?.data?.access_token){
    throw new Error('Error getting access token');
  }
	// Save tokens to localStorage together with a timestamp
	localStorage.setItem('code', code);
	localStorage.setItem('code_verifier', verifier);
	localStorage.setItem('access_token', tokenInfo.data.access_token);
	localStorage.setItem('refresh_token', tokenInfo.data.refresh_token);
	localStorage.setItem('last_saved_time', Date.now());
	return tokenInfo.data;
}
export { getOrRenewAccessToken };
