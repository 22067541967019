import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (type, message) => {
    const newAlert = { id: Date.now(), type, message }; // Unique ID for each alert
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);

    // Set a timeout to remove this specific alert
    setTimeout(() => {
      removeAlert(newAlert.id);
    }, 5000); // 5 seconds
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ alerts, addAlert, removeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};