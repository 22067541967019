import { Backdrop, Box, Button, CircularProgress, Container, InputAdornment, Stack, TextField, Typography, alpha, colors } from '@mui/material';
import { collection, doc, getDocs, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../auth/AuthContext';
import { createNewProjectInvitee, generateParticipantUID, updateGlobalInvitees } from '../../auth/AuthUtils';
import { useAlert } from '../../context/AlertContext';
import { db, functions } from '../../firebase';
import InviteesTable from '../components/InviteesDataTable';
import { httpsCallable } from 'firebase/functions';


const ProjectParticipants = () => {
  // Get projectId from the URL
  const { projectId } = useParams();
  // Project working with
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // User object from the AuthContext set in App.js
  const { currentUser, loading, userJamasp } = useAuthContext();

  const [showInviteeForm, setShowInviteeForm] = useState(false);
  const [invitees, setInvitees] = useState([]);
  const [editInviteeFormData, setEditInviteeFormData] = useState({});

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  /**
   * Retrieves project data from Firestore and updates the state on startup.
   *
   * @return {void}
   */
  useEffect(() => {
    setIsLoading(true);
    const projectDoc = doc(db, "projects", projectId);

    const unsubscribe = onSnapshot(projectDoc, (doc) => {
      if (doc.exists()) {
        setProject(doc.data());
        console.log('project found:', doc.data());

        //console.log(userDevices, userSensors, userSettings);

        /**
         *  Get invited users data from invitees collection
         */
        // Execute queries and combine results
        const invitedUsers = doc.data().invitedNewUsers; // Assuming invitedNewUsers is an array

        let invitedUsersData = [];
        getDocs(collection(db, "invitees")).then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (invitedUsers.filter((item) => item.invitationID === doc.data().invitationID)?.length > 0) {
              invitedUsersData.push(doc.data());
            }
          });
          console.log('invited users data:', invitedUsersData);
          setInvitees(invitedUsersData);
        });

      } else {
        addAlert('error', `Project with id: ${projectId} could not be found!`);
        setProject(null);
      }
    });
    console.log("invitees: ", invitees);
    setIsLoading(false);
    return unsubscribe;
  }, [projectId]);

  /**
   * Handles saving the project by updating the project document with the new user devices, sensors, and settings.
   *
   * @return {void} No return value.
   */
  const handleSaveProject = () => {
    setIsLoading(true);
    try {
      // Update the project document with the new user devices, sensors, and settings
      const updatedProject = {
        ...project,
        updatedAt: serverTimestamp()
      }
      if (updatedProject?.id === undefined) {
        console.log("poject id is undefined!!!!");
        updatedProject.id = projectId;
      }

      console.log("updatedProject: ", updatedProject);
      //const projectRef = doc(db, "projects", projectId);
      updateDoc(doc(db, "projects", projectId), updatedProject).then(() => {
        setProject(updatedProject);
        updateGlobalInvitees(updatedProject, projectId);
        addAlert('success', `Project ${updatedProject.name} with id: ${projectId} updated successfully`);
        console.log("Project updated successfully");
        setIsLoading(false);
      }).catch((error) => {
        addAlert('error', `Project ${updatedProject.name} with id: ${projectId} could not be updated!!!!`);
        console.error("Error updating project: ", error);
      })
    } catch (error) {
      addAlert('error', `Project with id: ${projectId} could not be updated!!!!`)
      console.error("Error updating project: ", error);
    }
    setIsLoading(false);
  }

  const handleInviteesTableAction = async (action, invitee) => {
    console.log("action: ", action);
    console.log("invitee: ", invitee);
    switch (action) {
      case 'delete':
        // delete the invitee
        console.log(project.invitedNewUsers);
        //const updatedInvitedNewUsers = project.invitedNewUsers.filter((item) => item.uid !== invitee.uid)
        project.invitedNewUsers.splice(project.invitedNewUsers.indexOf(invitee), 1);
        console.log("updatedInvitedUsers: ", project.invitedNewUsers);
        setProject({
          ...project,
          invitedNewUsers: project.invitedNewUsers
        });
        addAlert('success', `Invitee ${invitee.name} deleted successfully`);
        break;
      case 'send':
        // Send invitation email
        const sendInvitationEmail = async (invitee) => {
          const sendEmailAction = httpsCallable(functions, 'sendInvitationEmail');
          const message = `Hi. < br /> <br /> We’re contacting you about the Fitbit study you’re participating in. < br /> <br /> Please use this link to register your Fitbit device.https://jamasp.app/auth/register/${invitee.invitationID} <br />` +
            "You will be asked to log in using a gmail account linked to your device. <br /> Send us a message on prolific if you run into trouble registering. <br /> <br /> Thanks! <br /> <br />"
          const subject = "Fitbit Study Registration"
          sendEmailAction({ email: invitee.email, message: message, subject: subject })
            .then((result) => {
              console.log(result.data);
              addAlert('success', `Invitation email sent to ${invitee.email} successfully`);
            })
            .catch((error) => {
              console.error(error);
              addAlert('error', `Invitation email could not be sent to ${invitee.email}`);
            });
        }
        console.log('trying to send email');
        const sendResult = await sendInvitationEmail(invitee);
        console.log(sendResult);
        break;
      case 'sms':
        // Send invitation SMS
        const sendInvitationSMS = async (invitee) => {
          const message = `Hello ${invitee.name}, you have been invited to join the project: ${project.name}. Please click on the link to join: https://jamasp.app/auth/register/${invitee.invitationID}`
          const sendSMSAction = httpsCallable(functions, 'sendInvitationSMS');
          sendSMSAction({ to: invitee.phone, message: message })
            .then((result) => {
              console.log(result.data);
              addAlert('success', `Invitation SMS sent to ${invitee.phone} successfully`);
              return result;
            });
        }
        const smsResult = await sendInvitationSMS(invitee);
        console.log(smsResult);
        break;
      case 'edit':
        // edit the invitee
        break;
      case 'getTokenByForce':
        // Get the token by force
        const getTokenByForceAction = httpsCallable(functions, 'getAccessTokenForUser');
        const code = prompt("Enter the code sent to the user's email");
        const res = getTokenByForceAction({ uid: invitee.firebaseUserID, code: code })
          .then((result) => {
            console.log(result.data);
            addAlert('success', `Token fetched for ${invitee.email} successfully`);
            return result;
          }).catch((err) => {
            console.error(err);
            addAlert('error', `Token could not be fetched for ${invitee.email}`);
          });
        console.log(res);
        break;
      default:
        break;
    }
  }

  const regenerateUIDInput = () => {
    const uid = generateParticipantUID();

  }

  /**
   * Handles the event when a new user is invited.
   *
   * @param {Event} event - The event object.
   * @returns {boolean} - Returns true if added successfully, false otherwise.
   */
  const handleInviteNewUser = (event) => {
    event.preventDefault();
    // TODO: Add validation for the form fields
    //TODO: Check if the user is already in the system
    //TODO: Check if the user is already invited
    //TODO: Check if the user is already a member of the project
    const data = new FormData(event.currentTarget);
    const newInvitee = createNewProjectInvitee({
      uid: data.get('uid'),
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone'),
      role: data.get('role')
    });
    const updatedInvitedNewUsers =
      (project?.invitedNewUsers !== undefined && project?.invitedNewUsers !== null) ?
        project?.invitedNewUsers : [];
    updatedInvitedNewUsers.push(newInvitee);
    console.log(updatedInvitedNewUsers)
    setProject({
      ...project,
      invitedNewUsers: updatedInvitedNewUsers
    });
    addAlert('success', `Invitee ${newInvitee.name} added successfully`);
    //console.log("newInvitee: ", newInvitee);

    // updateDoc(doc(db, "projects", projectId), {
    //   invitedNewUsers: updatedInvitedNewUsers
    // });

    //TODO: Regenerate the UID
    event.target.reset();
    setShowInviteeForm(false);
    return true;
  };

  /**
   * Renders the project page.
   *
   * @returns {JSX.Element} - The rendered project page.
   */
  return (
    <Container>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h3">Project: {project?.name}</Typography>

        {(loading || isLoading) && (
          <Backdrop
            sx={{ color: alpha(colors.common.black, 0.5) }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {project?.invitedNewUsers && (
          <Box sx={{ pt: 2 }}>
            <Typography key="invitedUsersTitle" variant="h5">Manage Invited Users</Typography>
            <InviteesTable key="invitedUsersTable" inviteesData={project?.invitedNewUsers} invitees={invitees} handleInviteesTableAction={handleInviteesTableAction} />
          </Box>
        )}
        <Box sx={{ pt: 2 }} display={'flex'} flexDirection={'row'} justifyContent={'end'}>
          <Button color='primary' variant='contained'
            onClick={() => { setShowInviteeForm(!showInviteeForm) }}>Add New Participant</Button>
        </Box>
        <InviteeForm handleInviteNewUser={handleInviteNewUser} showInviteeForm={showInviteeForm} />
        <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleSaveProject}
          >
            Save Project
          </Button>
        </Stack>
      </Box>
    </Container >

  );
};

function InviteeForm({ handleUpdateInvitee, handleInviteNewUser, showInviteeForm, edit = false, inviteeData = null }) {
  return (
    <Box hidden={!showInviteeForm} key="invitingNewUserFormBox" component={'form'} onSubmit={edit ? handleUpdateInvitee : handleInviteNewUser} sx={{ mt: 2 }}>
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        id="uid-inviteeInput"
        label="Participant's UID"
        name="uid"
        autoComplete="off"
        autoFocus
        editable={edit ? "false" : "true"}
        disabled={edit ? true : false}
        value={edit ? inviteeData?.uid : generateParticipantUID()}
      />
      <TextField
        margin="normal"
        type="email"
        required
        fullWidth
        id="email-inviteeInput"
        label="Email Address"
        name="email"
        autoComplete="off"
        value={edit ? inviteeData?.email : ''}
      />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        id="name-inviteeInput"
        label="Full Name"
        name="name"
        autoComplete="fullName"
        value={edit ? inviteeData?.name : ''}
      />
      <TextField
        margin="normal"
        type="tel-cc"
        required
        fullWidth
        id="phone-inviteeInput"
        label="Phone"
        name="phone"
        autoComplete="off"
        pattern="+1[0-9]{10}"
        placeholder="+11234567890"
        title="Phone number must be in format: +1234567890"
        value={edit ? inviteeData?.phone : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              +1
            </InputAdornment>
          ),
        }}
      />
      <TextField
        margin="normal"
        type="password"
        fullWidth
        id="password"
        label="Password"
        name="password"
        autoComplete="disabled"
        value={edit ? inviteeData?.password : ''}
      />
      <TextField
        margin="normal"
        type="text"
        fullWidth
        id="role"
        label="Role"
        name="role"
        autoComplete="off"
        value={edit ? inviteeData?.role : ''}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {edit ? 'Update': 'Add'}
        {edit ? ' Invitee': ' Participant'}
      </Button>
    </Box>
  )
}

export default ProjectParticipants;
