import { Box, Button, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { functions, perf } from '../../firebase';
import { auth } from '../../firebase';
import { fetchFitBitUsers } from '../../auth/AuthUtils';

const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [authUsers, setAuthUsers] = React.useState([]);

  const navigate = useNavigate();

  useEffect(() => () => {
    if (!auth?.currentUser)
      return;
    auth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        console.log(idTokenResult);
        console.log("claimes: ", idTokenResult.claims);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authUsers]);
  // Fetch users on mount
  useEffect(() => {
    const fetchUsers = async () => {
      try { // TODO: later we should get users of the project only
        const getAllUsers = httpsCallable(functions, 'getAllUsers');
        getAllUsers()
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            console.log(result);
            setAuthUsers(data.users);
            // ...
          })
          .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            // ...)
            console.log(code, message, details);
          });
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
    fetchFitBitUsers().then((users) => {
      //console.log("users", users);
      setUsers(users);
    }).catch((error) => {
      console.log(error);
    });

  }, []);

  // Refresh users data
  useEffect(() => {
    //console.log("users", users);
    authUsers.forEach((user) => {
      refreshUsersData(user);
    });
  }, [authUsers]);


  const refreshUsersData = async function (user) {
    try {
      console.log("refreshUsersData", user);

    } catch (error) {
      console.log(error);
    }
  }

  function makeAdmin(userId) {
    console.log("makeAdmin", userId);
    const makeAdmin = httpsCallable(functions, 'toggleUserRole');
    makeAdmin({ uid: userId, role: 'admin' })
      .then(result => {
        console.log(result);
        //navigate('/admin/users');
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <Box>
      <Typography variant="h1" component="h1" gutterBottom>Users</Typography>
      <List>
        {authUsers.map((user) => (
          <ListItem
            key={user.uid}
            disablePadding
            secondaryAction={<Button edge="end" variant="contained" color="success" onClick={() => makeAdmin(user.uid)}>MakeAdmin</Button>}
          >
            <ListItemButton component={Link} to={`/admin/users/${user.uid}`} >
              <ListItemText primary={user.uid} secondary={user.email + ' | ' + console.log(user?.customClaims === undefined) +
                user?.customClaims === undefined ? 'No Data' : user?.customClaims?.role + ' | admin: ' + user?.customClaims?.admin} />
            </ListItemButton>
          </ListItem>

        ))}
      </List>
      <Typography variant="h3" component="h3" gutterBottom>Jamasp Users</Typography>
      <List>
        {users.map((user) => (
          <ListItem
            key={user.uid}
            disablePadding
            secondaryAction={<Button edge="end" variant="contained" color="success" onClick={() => refreshUsersData(user.uid)}>Refresh</Button>}
          >
            <ListItemButton component={Link} to={`/admin/users/${user.uid}`} >
              <ListItemText primary={user.name} />
            </ListItemButton>
          </ListItem>

        ))}
      </List>
    </Box>
  );
};
export default Users;