import { LockOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from '../context/AlertContext';
import { AuthContext } from './AuthContext'; // Import the AuthContext

function Login() {
  const { currentUser, signInWithGoogle , loding} = useContext(AuthContext); // Use the AuthContext
  const { addAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    if (loding) {
      // maybe trigger a loading screen
      return;
    }
    if (currentUser){
      addAlert("success", "You are now logged in");
      navigate("/user/home");
    } 
  }, [currentUser]);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Button
          onClick={signInWithGoogle} // Use the signInWithGoogle function from the AuthContext
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Google Sign In
        </Button>
      </Box>
    </Box>
  );
}
export default Login;