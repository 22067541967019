import React from "react";
import { Avatar, Button, Paper, TextField, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useRegistration } from "../../context/RegistrationContext";



const EmailLinkSignUpStep = () => {
  const {inviteeData , nextStep, error, setError ,handleSignUpWithEmailLink, currentUser, loading, localLoading, setLocalLoading} = useRegistration();

  const [email, setEmail] = React.useState(inviteeData?.email || '');
  const [emailError, setEmailError] = React.useState(false);
  
  const handleEmailInputChange = e => {
    setEmail(e.target.value);
    if (!e.target.validity.valid) {
      setError(e.target.validationMessage);
      setEmailError(true);
    } else {
      setError(false);
      setEmailError(false);
    }
  }

  return (
    <Paper variant="outlined" sx={{
      my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h4" variant="h5">
        Sign up with email
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" align="justify">
        You are about to sign up with email. We will send you an email to confirm your email address. Please click the link in the email to continue.
      </Typography>
      <TextField
        error={emailError}
        helperText={emailError && "Please enter a valid email address"}
        inputProps={{
          type: "email",
        }}
        variant="outlined" 
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        placeholder="Enter your email address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={handleEmailInputChange}
      />
      <Button
        onClick={() => handleSignUpWithEmailLink(email) } // Use the signInWithGoogle function from the AuthContext
        fullWidth
        id="send-email-link-button"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!email || emailError || loading || localLoading}
      >
        Send email verification link
      </Button>
    </Paper>
  );
}
export default EmailLinkSignUpStep;