import { Box } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';

/**
 * Renders the Terms component.
 * @returns {JSX.Element} The rendered Terms component.
 */
const Terms = () => {
  const [data, setData] = useState("");
  const url = '/terms.md';

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        // console.log(text);
        setData(text);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
      <Box sx={{ mt: 5, m: 1 }}>
        <ReactMarkdown>{data}</ReactMarkdown>
      {/* {data && <div dangerouslySetInnerHTML={{ __html: data }} />} */}
      </Box>
  );
};


export default Terms;