import * as React from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';

// Footer component
export default function Footer(props) {
  return (
    <Box
      sx={{
        borderTop: "1px solid #0000000a",
        marginTop: "auto",
        p: 4,
        display: "flex",
        justifyContent: "space-between"
      }}
      component="footer"
    >
      <Stack direction={"row"} spacing={2} >
        <Link href="/about" color="inherit">About</Link>
        <Link href="/privacy" color="inherit">Privacy</Link>
        <Link href="/terms" color="inherit">Terms</Link>
      </Stack>
        <Copyright { ...props} />
        <Link href="mailto:support@cut.social" color="inherit">Contact</Link>
    </Box>
  )
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jamasp.app/">
        Jamasp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}