import { Box, Button, Link, Paper, TextField, Typography } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useAlert } from '../../context/AlertContext';
import { useRegistration } from "../../context/RegistrationContext";
import { auth } from '../../firebase';


const PhoneVerifyStep = () => {
  const { inviteeData, updateInviteeData, saveInviteeData, nextStep, currentUser, setError, loading, localLoading, setLocalLoading, handleLinkAccountWithPhone, handleVerifyPhoneNumber } = useRegistration();
  const [phone, setPhone] = useState(inviteeData?.phone || '');
  const [phoneError, setPhoneError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [verificationData, setVerificationData] = useState({
    recaptchaVerifier: null,
    code: '',
    agreed: false,
    phoneNumber: inviteeData?.phone || '',
    confirmationResult: null,
    phoneVerified: false,
    phoneVerificationSentAt: null
  });

  const { addAlert } = useAlert();

  /**
     * Updates the verification data with the provided data.
     * @param {Object} data - The data to update the verification data with.
     */
  const updateVerificationData = (data) => {
    setVerificationData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setPhoneError(!e.target.validity.valid);
    if(e.target.validity.valid)
      updateVerificationData({ phoneNumber: e.target.value });
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setCodeError(!e.target.validity.valid);
    if(e.target.validity.valid)
      updateVerificationData({ code: e.target.value });
  };

  /**
   * Sets up the recaptcha verifier.
   */
  const setUpRecaptcha = () => {
    function onSignInSubmit(data = null) {
      // TODO(you): Implement
      console.log('onSignInSubmit');
      if (data) {
        console.log('data:', data);
      }


    }
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('recaptcha solved; response:', response);
        onSignInSubmit(response)

      },
      'expired-callback': () => {
        console.log('recaptcha expired');
      }
    });
  };

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    if (!phone || phoneError) {
      setPhoneError('Phone number is required');
      return;
    }
    try {
      setLocalLoading(true);
      setUpRecaptcha();
      const verificationId = await handleLinkAccountWithPhone(currentUser, verificationData.phoneNumber, window.recaptchaVerifier);
      setVerificationId(verificationId);
      addAlert('success', 'Verification code sent successfully.');
      const dataToBeUpdated = { confirmationResult: verificationId?.verificationId, phoneVerificationSentAt: Timestamp.now() };
      if(inviteeData.phone!==phone){
        dataToBeUpdated['phone'] = phone;
      }
      updateVerificationData(dataToBeUpdated);
      updateInviteeData(dataToBeUpdated);
      saveInviteeData(dataToBeUpdated);
      
    } catch (error) {
      console.error('Error sending verification code:', error);
    } finally {
      setLocalLoading(false);
    }
  };

  const verifyCode = async () => {
    try {
      setLocalLoading(true);
      const result = await handleVerifyPhoneNumber(verificationId, code);
      console.log('result:', result);
      if (result?.user) {
        addAlert('success', 'Phone number verified!');
        const dataToBeUpdated = {
          invitationStatus: 'phone_verified', phoneVerified: true,
          phoneVerificationSentAt: verificationData.phoneVerificationSentAt,
          phoneVerifiedAt: Timestamp.now(),
          phone: phone
        };
        updateVerificationData(dataToBeUpdated);
        await nextStep(dataToBeUpdated);
      }

      // Add code to sign in with the credential or handle it as needed.
    } catch (error) {
      console.error('Error verifying code:', error);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <Paper variant="outlined" sx={{
      my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }}>
      <Typography variant="h4" my={2}>Verify Phone</Typography>
      <Typography variant="subtitle" sx={{textAlign: 'center' }} gutterBottom> Please Click <b>Send Code</b> so we can verify Your Phone Number.</Typography>

      <TextField
        type="tel"
        value={phone}
        onChange={handlePhoneChange}
        placeholder="Enter phone number"
        error={!!phoneError}
        helperText={phoneError ? "Please enter a valid phone number with a country code, e.g. +11234567890" : ""}
        margin='normal'
        required
        autoFocus
        inputProps={{
          type: "tel-ccode",
          pattern: "^[+][0-9]{11,15}$"
        }}
        sx={{ width: '75%' }}
      />
      {!verificationId &&
        <>
          <Button id="sign-in-button" onClick={(e) => sendVerificationCode(e)}
            disabled={loading || localLoading || !!phoneError ? true : false}
            variant={loading || localLoading || phoneError ? "outlined" : "contained"}
          >
            Send Code
          </Button>
          <br />
          <Typography variant="subtitle">We will send you an SMS to confirm your phone number. Message & data rates may apply.By signing in, you agree to our <Link to="/privacy" target='_blank'>Terms of Service and Privacy Policy</Link>.</Typography>
        </>
      }
      {verificationId && (
        <>
          <TextField
            type="text"
            value={code}
            onChange={handleCodeChange}
            margin="normal"
            required
            placeholder="Enter verification code"
            error={!!codeError}
            helperText={codeError ? "Please enter a valid verification code" : ""}
            sx={{ width: '75%' }}
            inputProps={{
              type: "text",
              pattern: "^[0-9]{6}$"
            }}
          />
          <Button onClick={verifyCode} 
              disabled={loading || localLoading || !!codeError ? true : false}
              variant={loading || localLoading || !!codeError ? "outlined" : "contained"}              
          >Verify Code</Button>
        </>
      )}
    </Paper>
  );
};

export default PhoneVerifyStep;