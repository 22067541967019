// src/context/RegistrationContext.js

import React, { createContext, useContext, useState } from 'react';
import { completeRegsistration, generateRegistrationActionCodeSettings, getCurrentUserPhoneVerified, getInviteeDocument } from '../auth/AuthUtils';
import { useEffect } from 'react';
import { Timestamp, doc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useAuthContext } from '../auth/AuthContext';
import { useAlert } from './AlertContext';
import { useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { authMethod } from '../auth/Register';

// Create the context
const RegistrationContext = createContext();

// Export the custom hook to use the context in other components
export function useRegistration() {
  return useContext(RegistrationContext);
}

// Define the provider component that encapsulates children in the Registration Context
export function RegistrationProvider({ children }) {
  // Define the state that you want to track within the registration process
  const [activeStep, setActiveStep] = useState(0);
  // WHY DO WE NEED THIS? 
  //Is it additional registraion data from user document from database?
  //const [userData, setUserData] = useState({});                                        
  const [inviteeData, setInviteeData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { currentUser, loading: authLoading, userJamasp, signUpWithGoogle, linkWithPhoneNumber, verifyPhoneNumber } = useAuthContext();
  const { addAlert } = useAlert();
  const { navigate } = useNavigate();

  // Function to update invitee data state
  const updateInviteeData = (data) => {
    console.log(" updateInviteeData : ", data)
    validateAndUpdateInviteeUpdateData(data).then((res) => {
      console.log("validate result : ", res)
    }).catch((err) => {
      console.log(err)
      setError(err.message);
    })
    console.log("updateInviteeData inviteeData: %o", inviteeData);
  };

  // Function to update invitee data in firestore
  const saveInviteeData = async (data) => {
    console.log("saveInviteeData" , data);
    if (inviteeData === undefined || inviteeData === null || !data) return;
    try {
      const updatedData = {...inviteeData, ...data};
      console.log('save Invitee Data: %o', updatedData);
      const inviteeDoc = await getInviteeDocument(inviteeData.invitationID);
      if (inviteeDoc && inviteeDoc.exists()) {
        const saveRes = await updateDoc(inviteeDoc.ref, updatedData);
        console.log("Invitee data saved", saveRes);
        return Promise.resolve(saveRes);
      }
    } catch (err) {
      console.log(err);
      addAlert('error', "Something went wrong. Connection to server failed. Please try again.");
      return Promise.reject(err);
    }
    return Promise.reject("Invitee data not found");
  };

  // Function to change steps in the registration process
  const nextStep = async (data) => {
    console.log("handleNext activestep: %i data: %o", activeStep, data);
    setLoading(true);
    let newStep = activeStep;
    try {
      // ... Logic to proceed to the next step
      const userId = currentUser?.uid || data?.firebaseUserID || undefined;
      console.log("userId", userId);
      let additionalData = {};
      if (activeStep === 0) {
        additionalData = data !== undefined ? data : { registrationStartedAt: Timestamp.now() };
        newStep++;
        console.log("newStep", newStep);
      }
      if (activeStep === 1) {
        if( authMethod.emailAuthMethod === "EMAIL_LINK"){
          // check if google sign in is successful in the next step
          if (userId === undefined) {
            console.log("Error signing up with Google. Please try again.")
            addAlert('error', 'Error signing up with Google. Please try again.');
            setLoading(false);
            return;
          } else {
            additionalData = data !== undefined ? data : {};
            newStep++;
          }          
        }
        if( authMethod.emailAuthMethod === "GOOGLE_SIGN_IN"){
          // check if google sign in is successful in the next step
          if (userId === undefined) {
            console.log("Error signing up with Google. Please try again.")
            addAlert('error', 'Error signing up with Google. Please try again.');
            setLoading(false);
            return;
          } else {
            additionalData = data !== undefined ? data : {};
            additionalData.firebaseUserID = userId;
            newStep++;
          }
        }
      }
      if (activeStep === 2) {
        // check for phone verification!!!!
        console.log("step 3 data", data);

        const phoneVerified = getCurrentUserPhoneVerified();
        console.log("phoneVerified", phoneVerified);
        if (!phoneVerified) {
          addAlert('error', 'Phone number not verified. Please try again.');
          setLoading(false);
          return;
        } else {
          additionalData = data !== undefined ? data : { phoneVerified: true };
          newStep++;
        }
      }
      if (activeStep === 3) {
        // check for fitbit access
        newStep++;
        additionalData = data !== undefined ? data : {};
      }
      if (activeStep === 4) {
        // check if registration is complete and done then navigate to /user
        if (checkRegistrationComplete()) {
          console.log("registration complete");
          navigate("/user");
        } else {
          console.log("why are we here?")
        }
      }
      setActiveStep(newStep);
      console.log("newStep has been set , now updating database, userID : %s",userId);
      if (userId !== undefined) {
        await updateUserRegistrationStep(userId, newStep, additionalData);
      } else {
        if (activeStep > 1) {
          addAlert('error', 'Please sign in again to continue.');
          // TODO : be sure about the next two lines then remove the comment
          // newStep = 2
          // setActiveStep(newStep);

          // return newStep;
        }
        await updateInviteeRegistrationStep(newStep, additionalData);
      }
    } catch (err) {
      console.error("Error updating registration step", err);
      setLoading(false);
      setError(err);
      return;
    }
    setLoading(false);
    return newStep;
  };

  const previousStep = () => {
    // ... Logic to go back to the previous step
    if (activeStep > 1) {
      addAlert('error', 'Please complete the previous step before going back.');
      return;
    }
    setActiveStep((prevStep) => prevStep - 1);
  };

  // check and validate updateing the invitee data
  const validateAndUpdateInviteeUpdateData = async (data) => {
    if (data?.registrationStep === -1 || inviteeData?.registrationStep === -1) {
      console.log("updateInviteeData: registrationStep is -1");
      data.registrationStep = 0;
      data.registrationStartedAt = Timestamp.now();
      data.registrationStatus = 'Started'
      setActiveStep(0);
    }
    // TODO: Check if the registration data regarding the step is valid
    // if (data?.registrationStep === 1) {
    //   if (!data?.emailVerified) {}
    // }

    /**
    * if step >1 and no user is logged in we should goback to step 1 then skip step 2 if phone is vefied and linked to the user
    */ 
    // if (activeStep > 1 && !loading && !authLoading && !currentUser) {
    //   console.log("updateInviteeData: registrationStep is greater than 1 and no user is logged in");
    //   data.registrationStep = 1;
    //   setActiveStep(1);
    //   return {
    //     success: false,
    //     message: 'Please sign in to continue.'
    //   }
    // }
    // if (data?.registrationStep === 2) {
    //   const phoneVerified = getCurrentUserPhoneVerified();
    //   if (phoneVerified) {
    //     data.phoneVerified = true;
    //     data.registrationStep = 3;
    //   }
    // }
    if (data?.registrationStep === 3) {
      // TODO: Check if the fitbit data is valid
      if(userJamasp?.fitbitData?.access_token?.lenght > 0){
        completeRegsistration(currentUser);
      }
    }

    if (data?.registrationStep === 4) {
      // TODO: Check if the registration is complete)
    }

    // Supposing the data is valid, update the invitee data
    // TODO: Check if we should update the step based on the invitee data?
    if (data?.registrationStep !== undefined && data?.registrationStep !== null && data?.registrationStep !== inviteeData?.registrationStep) {
      setActiveStep(data.registrationStep);
    }
    setInviteeData((prevData) => ({ ...prevData, ...data }));
    return {
      success: true,
      message: 'Invitee data updated successfully'
    }
  }

  const handleSignUpWithEmailLink = async (email) => {
    console.log("handleSignUpWithEmailLink");
    console.log("signUpWithEmailLink input email : %s", email);
    setLoading(true);
    try {
      const actionCodeSettings = generateRegistrationActionCodeSettings(inviteeData?.invitationID);
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          console.log("sendSignInLinkToEmail done.");
          addAlert('success', 'An email has been sent to you. Please click the link in the email to continue.');
          window.localStorage.setItem('emailForSignIn', email);
          const additionalData = {
            email: email,
            emailVerified: false,
            emailVerificationSentAt: Timestamp.now(),
          }
          updateInviteeData(additionalData);
          saveInviteeData(additionalData).then(() => {
            console.log("saveInviteeData done.");
          }).catch((err) => {
            console.error('Error saving invitee data:', err);
            addAlert('error', 'Error saving invitee data. Please try again.');
            setError(err.message);
          });
        }).catch((err) => {
          console.error('Error sending email link:', err);
          addAlert('error', 'Error sending email link. Please try again.');
          setError(err.message);
        });
    } catch (err) {
      console.error('Error sending email link:', err);
      addAlert('error', 'Error sending email link. Please try again.');
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSignUpWithGoogle = async (email) => {
    console.log("handleSignUpWithGoogle");
    if (loading || authLoading) {
      addAlert('error', 'Please Try Again.');
      console.log("handleSignUpWithGoogle loading, so we are returning and doing nothing.");
      return
    }
    console.log("signUpWithGoogle input email : %s", email);
    const singUpProcess = async (email) => {
      console.log('singUpProcess, input email : %s', email);
      try {
        signUpWithGoogle()
          .then((user) => {
            console.log("google sign up user , user: %o", user);
            if (user !== null && user !== undefined) {
              console.log('user email : %s , input email : %s ,and invitee email : %s',
                user.email, email, inviteeData.email);

              // if (user.email !== inviteeData.email) {
              if (user.email !== email) {
                addAlert('error', 'You cannot sign in with a different email. Please use the link provided in the email to change the email.')
                alert('You cannot sign in with a different email. Please use the link provided in the email to change the email.');
                // TODO: for now we let the user to sign in with a different email, but we should not let them to do so.
                //return null;
              }
              console.log('user is not null we are returning user', user.uid)
              const additionalInviteeData = {
                firebaseUserID: user.uid,
                //inviteStatusUpdatedAt: serverTimestamp(),
                invitationStatus: 'email_verified',
                registrationStepUpdatedAt: Timestamp.now(),
              };
              addAlert('success', 'You have successfully signed in with Google.');
              nextStep(additionalInviteeData);
            }
            return user;
          }).catch((err) => {
            console.error('Error during Google Sign-In:', err);
            alert('errror' + err.message);
            return null;
          });
      } catch (err) {
        console.error('Error during Google Sign-In:', err);
        return null;
      }
    }
    setLoading(true);
    await singUpProcess(email);
    setLoading(false);
  }

  const handleLinkAccountWithPhone = async (user, phoneNumber, appVerifier) => {
    return linkWithPhoneNumber(user, phoneNumber, appVerifier);
  }

  const handleVerifyPhoneNumber = async (phoneNumber, appVerifier) => {
    return verifyPhoneNumber(phoneNumber, appVerifier);
  }

  // Function to update Firestore
  const updateUserRegistrationStep = async (userId, step, data) => {
    console.log("updateUserRegistrationStep, inviteeData : %o" ,inviteeData);
    setLoading(true);
    console.log("updateUserRegistrationStep", userId, step, data);
    if (typeof userId !== 'string') {
      console.log("updateUserRegistrationStep: userId is not a string");
      return;
    }
    const additionalUserData = {
      registrationStep: step
    };
    if (step === 1) {
      data.registrationStartedAt = Timestamp.now();
      data.registrationStatus = 'Started';
      additionalUserData.intvitationId = inviteeData.invitationID;
      additionalUserData.inviteeUID = inviteeData.uid;
      additionalUserData.creationMethod = "Registration";
      additionalUserData.createdAt = Timestamp.now();
      additionalUserData.status = "Registering"
    }
    if (step === 2) {
      data.registrationStatus = 'Email Verified';
      data.emailVerifiedAt = Timestamp.now();
      data.emailVerified = true;
      data.firebaseUserID = userId;
      additionalUserData.inviteeUID = inviteeData.uid;
      additionalUserData.invitationId = inviteeData.invitationID;
      additionalUserData.creationMethod = "Registration";
      additionalUserData.emailVerified = true;
      additionalUserData.emailVerifiedAt = Timestamp.now();
      additionalUserData.uid = userId;
    }
    if (step === 3) {
      data.registrationStatus = 'Phone Verified';
      data.phoneVerifiedAt = Timestamp.now();
      data.phoneVerified = true;
      additionalUserData.phone = data.phone;
      additionalUserData.phoneVerified = true;
    }
    if (step === 4) {
      data.registrationStatus = 'Completed';
      additionalUserData.registrationCompletedAt = Timestamp.now();
      additionalUserData.status = "Active"
    }
    const userRef = doc(db, 'users', userId);
    const res = await setDoc(userRef, additionalUserData, { merge: true });
    console.log("updateUserRegistrationStep", res);
    const updateIviteeDataAsync = async () => {
      if (typeof inviteeData.invitationID !== 'string'){
        console.log("updateUserRegistrationStep: inviteeData.invitationID is not a string", typeof inviteeData?.invitationID);
        return;
      } 
      const inviteeDoc = await getInviteeDocument(inviteeData.invitationID);
      if (inviteeDoc && inviteeDoc.exists() && inviteeDoc.data()) {
        const updatedData =
        {
          ...data, registrationStep: step,
          inviteStatusUpdatedAt: Timestamp.now(), registrationStepUpdatedAt: Timestamp.now()
        };
        console.log("invitee data fetched and updated", updatedData);
        const res2 = await setDoc(inviteeDoc.ref, updatedData, { merge: true });
        console.log("updateInviteeRegistrationStep", res2);
        return res2;
      }
    };
    console.log('updating invitee doc');
    await updateIviteeDataAsync();
    setLoading(false);
    return res;
  };

  const updateInviteeRegistrationStep = async (step, data) => {
    setLoading(true);
    console.log("updateInviteeRegistrationStep , step : %s , data : %o , inviteeData : %o", step, data, inviteeData);

    try {
      const inviteeDoc = await getInviteeDocument(inviteeData.invitationID);
      console.log("updateInviteeRegistrationStep", step, data, inviteeDoc);
      const additionalInviteeData = {
        ...data, registrationStep: step,
        invitationStatusUpdatedAt: Timestamp.now(), registrationStepUpdatedAt: Timestamp.now()
      };
      updateInviteeData(additionalInviteeData);
      const res = await setDoc(inviteeDoc.ref, additionalInviteeData, { merge: true });
      console.log("updateInviteeRegistrationStep: %o , updatedInviteeData: %o", res, additionalInviteeData);
      setLoading(false);
      return res;
    } catch (err) {
      console.error("Error updating invitee registration step", err);
      setLoading(false);
      return err;
    }
  }

  // check if registration is complete and done
  const checkRegistrationComplete = () => {
    // TODO: complete it
    return activeStep === 5;
  }

  // fetch invitee data from firestore to sync with the inviteeData state
  const fetchInviteeData = async (inviteeId) => {
    setLoading(true);
    console.log('fetchInviteeData', inviteeId);
    if (inviteeId) {
      try {
        const inviteeData = await getInviteeDocument(inviteeId)
        setInviteeData(inviteeData.data())
        console.log(inviteeData.data())
      } catch (err) {
        console.log(err)
      }
    }
    setLoading(false);
  };

  // Provide the context values that will be accessible to the children
  const value = {
    activeStep,
    inviteeData,
    setInviteeData, // Why have both updateInviteeData and setInviteeData?
    updateInviteeData,
    saveInviteeData,
    // userData, // Do we really need this? can't we remove it somehow?
    // updateUserData,
    nextStep,
    previousStep,
    error,
    setError,
    success,
    setSuccess,
    localLoading: loading,
    setLocalLoading: setLoading,
    loading: authLoading,
    currentUser,
    userJamasp,
    handleSignUpWithEmailLink,
    handleSignUpWithGoogle,
    handleLinkAccountWithPhone,
    handleVerifyPhoneNumber,

    checkRegistrationComplete
    // Add other relevant functions and data here as needed
  };

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  );
}