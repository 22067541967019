import { Alert, Backdrop, Button, Container, Grid, LinearProgress, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import { auth, db } from '../../firebase';
import ProjectCard from '../components/ProjectCard';



/**
 * Represents the admin dashboard page.
 * @returns {JSX.Element} The admin dashboard component.
 */
const AdminDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  // Get all projects from the database
  useEffect(() => {
    // I should make a new function to get all users from firebase-auth admin sdk
    setIsLoading(true);
    const unsubscribe = onSnapshot(collection(db, 'projects'), (snapshot) => {
      setProjects(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    setIsLoading(false);
    return unsubscribe;
  }, []);

  /**
   * Handles the deletion of a project.
   * @param {string} projectId - The ID of the project to be deleted.
   */
  const handleDeleteProject = (projectId) => {
    var projectName = undefined;
    setIsLoading(true);
    try {
      projectName = projects.filter((project) => project.id === projectId).at(0).name;
      console.log(`Deleting project ${projectName} with id: ${projectId}`);
      deleteDoc(doc(db, "projects", projectId)).then(() => {
        //console.log(`Project with id: ${projectId} deleted successfully`);
        setProjects(projects.filter((project) => project.id !== projectId));
        setAlert({ type: 'success', message: `Project ${projectName} with id: ${projectId} deleted successfully` });
        setTimeout(() => setAlert(null), 5000); // Hide the alert after 5 seconds
      }).catch((error) => {
        setAlert({ type: 'error', message: `Project with id: ${projectId} could not be deleted!` });
        setTimeout(() => setAlert(null), 5000); // Hide the alert after 5 seconds
        console.error("Error deleting project: ", error);
      });
    } catch (error) {
      setAlert({ type: 'error', message: `Project with id: ${projectId} could not be deleted!` });
      setTimeout(() => setAlert(null), 5000); // Hide the alert after 5 seconds
      console.error("Error deleting project: ", error);
    }
    setIsLoading(false);
  }

  /**
   * This function handles the various actions that can be performed on a project.
   * @param {} param0
   */
  const handleProjectAction = ({ project, action, data }) => {
    //TODO: implement the actions
  }

  const initializeBigQueryTables = async () => {
    try {
      setIsLoading(true);

      // await initializeBigQuery();
      //this function is not implemented yet, it should call appropriate cloudRun function and get the result.


      setIsLoading(false);
      alert('BigQuery tables created successfully!');
    } catch (error) {
      console.error('Error creating tables:', error);
      alert('Error creating tables. Please check the console.');
    }
  }

  return (
    <Container sx={{ mt: 1 }}>
      <Typography variant="h4" sx={{ mt: 1 }} gutterBottom>Admin Dashboard</Typography>
      <Typography variant="body1">Welcome <strong>{auth?.currentUser?.displayName}</strong>! This is your dashboard where you can see your projects. </Typography>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <LinearProgress color="inherit" />
        </Backdrop>
      )}
      {alert && <Alert severity={alert.type}>{alert.message}</Alert>}
      {!isLoading && projects.length === 0 && <Typography variant="body1">You have no projects.</Typography>}
      <Grid container spacing={2}>
        {projects.map((project) => (
          <Grid item xs={12} lg={6} key={project.id}>
            <ProjectCard key={project.id} project={project} handleDeleteProject={handleDeleteProject} handlePorjectAction={handleProjectAction} />
          </Grid>
        ))}
      </Grid>
      {!isLoading && projects.length > 0 && (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {projects.map((project) => {
            return (
              <ListItem
                key={project.id}
                disablePadding
                secondaryAction={<Button edge="end" variant="contained" color="error" onClick={() => handleDeleteProject(project.id)}>Delete</Button>}
              >
                <ListItemButton component="button" to={`/admin/showProject/${project.id}`}>
                  <ListItemText primary={project.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!isLoading && (
        <Button variant="contained" sx={{ mt: 2 }} onClick={initializeBigQueryTables}>Initialize BigQuery Tables</Button>
      )}
      <Button variant="contained" sx={{ mt: 2, float: 'right' }} onClick={() => navigate('/admin/newProject')}>Add Project</Button>
    </Container>

  )
};

export default AdminDashboard;
