import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminDashboard from "./admin/pages/AdminDashboard";
import NewProject from "./admin/pages/NewProject";
import ShowProject from "./admin/pages/ShowProject";
import ShowUser from "./admin/pages/ShowUser";
import Users from "./admin/pages/Users";
import { AuthProvider } from './auth/AuthContext';
import Login from "./auth/Login";
import PhoneLogin from "./auth/PhoneLogin";
import PhoneVerify from "./auth/PhoneVerify";
import Register from "./auth/Register";
import { auth } from "./firebase";
import AuthLayout from "./layout/AuthLayout";
import BlankLayout from "./layout/BlankLayout";
import ProtectedLayout from "./layout/ProtectedLayout";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import UserProfile from "./pages/UserProfile";
import FitbitCallback from "./auth/FitbitCallback";
import Tests from "./admin/pages/Tests";
import { RegistrationProvider } from './context/RegistrationContext';
import ProjectParticipants  from './admin/pages/ProjectParticipants';
// eslint-disable-next-line import/no-anonymous-default-export

export default function App() {

    return (
        <div className="app">
            <AuthProvider>
                <Router future={{ v7_startTransition: true }}>
                    <Routes>
                        {/* User routes */}
                        <Route path="/user" element={<ProtectedLayout />}>
                            {/* Redirect to adminDashboard if admin */}
                            <Route exact path="/user" element={<Navigate to="/user/home" replace />} />
                            <Route exact path="/user/home" element={<Dashboard />} />
                            <Route exact path="/user/profile" element={<UserProfile />} />
                        </Route>

                        {/* Admin routes */}
                        <Route path="/admin" element={<ProtectedLayout role={'admin'} />}>
                            <Route exact path="/admin" element={<AdminDashboard />} />
                            <Route exact path="/admin/users" element={<Users />} />
                            <Route path="/admin/users/:userId" element={<ShowUser />} />
                            <Route exact path="/admin/newProject" element={<NewProject />} />
                            <Route exact path="/admin/showProject/:projectId" element={<ShowProject />} />
                            <Route exact path="/admin/tests" element={<Tests />} />
                            <Route exact path="/admin/showProjectParticipants/:projectId" element={<ProjectParticipants />} />
                            {/* I don't know why the logout route makes the user to logout immediately. */}
                            {/* Also after figuring that out, where to place it here or under /auth route */}
                            {/* <Route exact path="/auth/signout" element={logout()} /> */}
                        </Route>

                        {/* Auth routes */}
                        <Route path="/auth" element={<AuthLayout />} > {/* Wrap nested routes inside a Route component */}
                            <Route exact path="/auth/register/:invitationID" element={<RegistrationProvider><Register /></RegistrationProvider>} />
                            <Route exact path="/auth/login" element={<Login />} />
                            <Route exact path="/auth/phoneLogin" element={<PhoneLogin />} />
                            <Route exact path="/auth/phone-verify" element={<PhoneVerify />} />
                            <Route exact path="/auth/fitbit_callback" element={<RegistrationProvider><FitbitCallback /></RegistrationProvider>} />
                            <Route exaxt path="/auth/logout" action={() => {
                                auth.signOut();
                                //console.log("logout", logout);
                                //navigate("/");
                            }} />
                            {/* <Route exact path="/auth/google_callback" element={<OAuthCallback />} /> */}
                            {/* <Route path="*" element={<Navigate to="/notFound" />} /> */}
                        </Route>

                        {/* Public routes */}
                        <Route path="/" element={<BlankLayout />} >
                            <Route exact path="/" element={<Navigate to="/home" replace />} />
                            <Route exact path="/home" element={<Home />} />
                            <Route exact path="/about" element={<About />} />
                            <Route exact path="/terms" element={<Terms />} />
                            <Route exact path="/privacy" element={<Privacy />} />
                            <Route exact path="/notFound" element={<NotFound />} /> {/* Add a route for the 404 page */}
                            <Route path="*" element={<Navigate to="/notFound" replace />} />
                        </Route>
                    </Routes>
                </Router>
            </AuthProvider>
        </div>
    );
}