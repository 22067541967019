import { Alert, Backdrop, Box, CircularProgress, Container, LinearProgress } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import Footer from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import { useAlert } from '../context/AlertContext';

const ProtectedLayout = ({ role, children }) => {
  const { currentUser, loading, phoneVerified, userJamasp } = useAuthContext();
  const { alerts, addAlert } = useAlert();
  const navigate = useNavigate();

  //console.log("ProtectedLayout : %o %o %o %o : %s", currentUser, loading, phoneVerified, userJamasp, role);

  useEffect(() => {
    // Fetch user data again if it's still null
    if (!loading && !currentUser) {
      console.log("it's not loading and currentUser is null", loading, currentUser);
      navigate('/auth/login')
    }
    if (userJamasp)
      console.log("userJamasp: %o , currenUser: %o", userJamasp, currentUser);
    if (!loading && currentUser && userJamasp && !userJamasp?.phoneNumberVerified) {
      console.log("ProtectedLayout is redirecting to phone-verify", currentUser, userJamasp, loading, phoneVerified);
      navigate('/auth/phone-verify')
    }
    if (!loading && !!role && role === 'admin' && !userJamasp?.claims?.admin) {
      console.log("ProtectedLayout is redirecting to adminDashboard", currentUser, userJamasp, loading, phoneVerified);
      console.log("ProtectedLayout role: %o, claims: %s", role, currentUser?.customClaims);
      addAlert("error", "You are not authorized to access this page");
      navigate('/');
    }

    // This really good for fitbit auth lets use it there
    // if (!loading && userJamasp && !phoneVerified) {
    //   navigate('/auth/phone-verify')
    // }
    // if (!loading && userJamasp && phoneVerified) {
    //   console.log("ProtectedRoute is redirecting to dashboard", currentUser, loading, phoneVerified);
    //   //navigate('/home');
    // }
  }, [userJamasp, loading, phoneVerified, currentUser]);



  //return userJamasp ? null : children ? children : (
  return children ? children : (
    <Container maxWidth="xl">
      <Box minHeight="100vh" display="flex" flexDirection="column" >
        <Navbar role={role} />
          {loading && (
            // Show a loading indicator while the user checks if they are logged in
            <Fragment>
              <Backdrop
                sx={{ color: '#fff' }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <LinearProgress />
            </Fragment>
          )}
          {alerts.map((alert, index) => (
            <Alert key={index} severity={alert.type}>
              {alert.message}
            </Alert>
          ))}
          <Outlet />

        <Footer />
      </Box>
    </Container>
  );

}

export default ProtectedLayout