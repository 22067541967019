import { getAdditionalUserInfo, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RegistrationStepper from '../components/auth/RegistrationStepper';
import { useAlert } from '../context/AlertContext';
import { auth } from '../firebase';
import {  getInviteeDocument, getOrGenerateUserDocumentFromFirebaseUser } from './AuthUtils';
import { useRegistration } from '../context/RegistrationContext';
import { Paper, Typography } from '@mui/material';

export const authMethod = {
  //emailAuthMethod: ['EMAIL_LINK', 'GOOGLE_SIGN_IN']
  emailAuthMethod: "GOOGLE_SIGN_IN"
}

const Register = () => {
  const [inviteeExists, setInviteeExists] = useState(null);

  const { activeStep, inviteeData, updateInviteeData, saveInviteeData, nextStep, prevStep, userData, updateUserData, error, setError, localLoading, loading , setLocalLoading, success, setSuccess } = useRegistration();

  // Get invitationID from the URL
  const { invitationID } = useParams();
  const { addAlert } = useAlert();

  /**
   * Handles automatically signing-in the app if we clicked on the sign-in link in the email.
   */
  const handleSignIn = () => {
    console.log("handleSignIn")
    setLocalLoading(true);
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Get the email if available.
      let email = window.localStorage.getItem('emailForSignIn');
      console.log("isSignInWithEmailLink in, email : %s, inviteeData.email : %s", email, inviteeData?.email);
      if (!email || email !== inviteeData.email) {
        addAlert('error', 'The email address in the link is not the same as the one in the invitation.');
        if (email?.length < 2 && inviteeData.email?.length < 2) {
          console.log('handleSignIn: email is not available in the link or in the invitation, what the fuck !!!!');
          //throw error
          addAlert('error', 'something went wrone!!')
          // User opened the link on a different device. To prevent session fixation attacks, ask the
          // user to provide the associated email again. For example:
          // TODO: do s.t elese
          email = window.prompt(
            "Please provide the email you'd like to sign-in with for confirmation.",
          );
          updateInviteeData({ email: email });
          saveInviteeData();
        }
      }
      if (email) {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then(function (result) {
            // Clear the URL to remove the sign-in link parameters.
            window.history.replaceState(
              {},
              document.title,
              window.location.href.split('?')[0],
            );
            console.log("signInWithEmailLink result iviteeData : %o ", inviteeData);
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // Signed-in user's information.
            const user = result.user;
            const additionalUserInfo = getAdditionalUserInfo(result);
            const isNewUser = additionalUserInfo?.isNewUser;
            console.log("signInWithEmailLink result : %o , newUser : %s", result, additionalUserInfo?.isNewUser);
            if (!!user) {
              // User  is signed in, and email is verified.
              console.log('handleSignIn: user is signed in, and email is verified. ');
              const additoinalUserData = {
                emailVerified: true,
                emailVerifiedAt: Timestamp.now(),
                isNewUser: isNewUser || false,
                name: inviteeData?.name || user?.displayName || '',
              }
              if(inviteeData?.uid){
                additoinalUserData['inviteeUid'] = inviteeData.uid;
              }
              getOrGenerateUserDocumentFromFirebaseUser(user, additoinalUserData).then((userDoc) => {
                console.log("userDoc : %o", userDoc);
              });
              const dataToUpdate = {
                emailVerified: true,
                emailVerifiedAt: Timestamp.now(),
              }
              if (isNewUser) {
                dataToUpdate['isNewUser'] = true;
              }
              addAlert("success", "Email verified successfully.");
              nextStep(dataToUpdate);
              setLocalLoading(false);
              return;
            }else{
              addAlert("error", "Something went wrong.");
              setLocalLoading(false);
              return;
            }
          })
          .catch(function (error) {
            // Some error occurred, you can inspect the code
            addAlert("error", error.message);
            setLocalLoading(false);
            return;
          });
      }
    }
    setLocalLoading(false);
  }

  useEffect(() => {
    try {
      window.tawk_load();
    } catch (err) {
      console.log(err);
    }
  }, [])

  useEffect(() => {
    if(loading || localLoading){
      return;
    }
    if(inviteeData?.registrationStep === 1 && authMethod.emailAuthMethod === "EMAIL_LINK")
      handleSignIn();
    console.log('Register.js useEffect , inviteeData : %o', inviteeData);

  },[localLoading, loading, inviteeData])

  // Fetch invitee data after invitationID is available
  useEffect(() => {
    console.log('Register.js useEffect[invitationID] , inviteeData : %o , invitationID : %s', inviteeData, invitationID);
    setLocalLoading(true);
    console.log('Register.js invitationID useEffect , invitationID : %s', invitationID);
    if (!inviteeData?.invitationID && !!invitationID) {
      console.log('Register.js updating invitationID in useEffect');
      updateInviteeData({ invitationID: invitationID })
    }
    if (!!invitationID) {
      getInviteeDocument(invitationID).then((inviteeDoc) => {
        console.log('invitationID useEffect , invitationID : %s , data : %o', invitationID, inviteeDoc?.data());
        if (inviteeDoc?.data()) {
          const data = inviteeDoc.data();
          setInviteeExists(true);
          updateInviteeData(data);
        }
        if (!inviteeDoc?.data()) {
          addAlert('error', 'Invitation not found!, The invitation you are trying to use is not valid or has expired.');
          setInviteeExists(false);
        }
      })
      .catch((err) => {
        console.log('invitationID useEffect , invitationID : %s , error : %o', invitationID, err);
        addAlert('error', err.message);
        error(err.message)
        setInviteeExists(false);
      });      
    }

    setLocalLoading(false);
  }, [invitationID]);


  return (
    <>
      {inviteeExists === false && (
        <Paper variant="outlined" sx={{
          my: { xs: 1, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography variant="h5" color="error">Invitation not found!</Typography>
          <Typography variant="subtitle1" color="text.secondary" align="justify">
            The invitation you are trying to use is not valid or has expired.
          </Typography>
        </Paper>
      )}
      {inviteeExists === true && (
        <RegistrationStepper
          emailAuthMethod={authMethod.emailAuthMethod}
        />
      )}
    </>
  );
};

export default Register;
