import { linkWithPhoneNumber as fbLinkWithPhoneNumber, sendSignInLinkToEmail, signInWithPopup, signOut } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAlert } from '../context/AlertContext';
import { auth, db, googleProvider } from '../firebase';
import { getCurrentUserDocument, getCurrentUserPhoneVerified, getUserDocument } from './AuthUtils';

// Create a context
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [phoneVerified, setPhoneVerified] = useState(false); // Add a phoneVerified state
  const [phoneNumber, setPhoneNumber] = useState(null); // Add a phone number state
  const [userJamasp, setUserJamasp] = useState(null); // Add a user in jamasp state

  const { addAlert } = useAlert();

  useEffect(() => {
    // TODO: Check the this logic completely, this one runs only once and if userDoc doesn't exist it will throws an error
    // This is a good method for checking user has validated phone number, but not in registration process

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log("auth.onAuthStateChanged", user, user?.isNewUser);
      setCurrentUser(user);  // Synchronous operation
      if (user && user?.uid) {
        try {
          const userDoc = await getCurrentUserDocument();
          if (userDoc !== null && userDoc?.data()) {
            setUserJamasp(userDoc.data());
            if (userDoc.data()?.claims) {
              setUserClaims(userDoc.data()?.claims);
            }
            console.log("onAuthStateChanged, getCurrentUserPhoneVerified :", getCurrentUserPhoneVerified());
            setPhoneVerified(getCurrentUserPhoneVerified());
            setPhoneNumber(userDoc.data()?.phoneNumber || userDoc.data()?.phone);
          }
        } catch (err) {
          console.log("Error in getting user data, maybe its registering or trying to get user data", err);
          addAlert("error", "Error in getting user data. Please try again. Or contact support.");
        } finally {
          setLoading(false); // Set loading to false once user is fetched
        }
      } else {
        setLoading(false); // Set loading to false when no user is logged in
      }
    });

    return () => unsubscribe();
  }, []);

  // TODO: sign in and sign up should be different in a way that sign up should check if the user is already signed up or not
  // now they are the same.
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const userDoc = await getUserDocument(user.uid);
      if (userDoc === null) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          photoURL: user.photoURL,
          createdAt: serverTimestamp(),
        }).catch((err) => {
          console.error(err);
          addAlert("error", "Error in creating user. Please try again. Or contact support.");
        });
      }
      return user;
    } catch (err) {
      console.error(err);
      addAlert("error", "Error in creating user. Please try again. Or contact support.");
      // TODO: setup alerts
    }
  };

  // TODO: sign in and sign up should be different in a way that sign up should check if the user is already signed up or not
  // now they are the same.
  const signUpWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;

      const userDoc = await getUserDocument(user.uid).catch((err) => {
        console.log("can't get user doc, maybe registering", err);
        return null;
      });
      if (userDoc === null) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          photoURL: user.photoURL,
          createdAt: serverTimestamp(),
        }).catch((err) => {
          console.error(err);
          addAlert("error", "Error in creating user. Please try again. Or contact support.");
        });
      }
      return user;
    } catch (err) {
      console.error(err);
      addAlert("error", "Error in creating user. Please try again. Or contact support.");
      // TODO: setup alerts
      return null;
    }
  }

  const logout = async () => {
    signOut(auth).then((callback) => {
      // Do the sign out stuff here
      //TODO: check if here is a good place for doing this
      console.log("Sign-out successful");
      if(callback) {
        callback();
      }
      // navigate to home page
      // navigate('/', { replace: true });
    }).catch((error) => {
      console.log(error);
      addAlert("error", "Error in logging out. Please try again. Or contact support. \\n" + error.message);
      //todo: setup alerts
      //todo: log with firebase
    });
  };

  const signUpWithEmailLink = async (email, actionCodeSettings) => {
    console.log("signUpWithEmailLink", email, actionCodeSettings);
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        // ...
        return { success: true };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        return { success: false, errorCode: errorCode, errorMessage: errorMessage };
        // ...
      });
  };

  const linkWithPhoneNumber = async (user, phoneNumber, appVerifier) => {
    setPhoneNumber(phoneNumber);
    const confirmationResult = await fbLinkWithPhoneNumber(user, phoneNumber, appVerifier);
    console.log("confirmationResult: %o", confirmationResult);
    return confirmationResult;
  };

  const verifyPhoneNumber = async (confirmationResult, code) => {
    const verifyResult = await confirmationResult.confirm(code);
    console.log("verifyResult: %o", verifyResult);
    //TODO: Check the verify result and also for errors
    if (verifyResult !== null) {
      console.log('Phone number verified', verifyResult);
      setPhoneVerified(true)
      const userDoc = await getUserDocument(auth.currentUser.uid);
      const res = await setDoc(userDoc.ref,
        {
          phoneNumber: phoneNumber,
          phoneNumberVerified: true,
          phoneVerifiedAt: serverTimestamp()
        },
        { merge: true });
      console.log((res));
    }
    return verifyResult;
  };


  // TODO: this function should be moved to another file
  // TODO: user claim is mocked
  function hasRole(role) {
    if (!userClaims)
      return false;
    if (role === "admin")
      return userClaims.admin;
    // mock userClaims
    if (role === "user")
      return true;
    if (!userClaims?.role)
      return false;
    return userClaims.role === role;
    //return true;   
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userJamasp,
        signInWithGoogle,
        signUpWithGoogle,
        logout,
        linkWithPhoneNumber,
        verifyPhoneNumber,
        signUpWithEmailLink,
        loading,
        phoneVerified,
        hasRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
