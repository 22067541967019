/**
 * Fetches devices based on the provided users.
 *
 * @param {Array} users - An array of user objects.
 * @returns {Promise} - A promise that resolves to the fetched devices.
 */

/**
 * Fetches humidity sensor data.
 *
 * @returns {Promise} - A promise that resolves to the fetched humidity data.
 */

/**
 * Fetches pressure sensor data.
 *
 * @returns {Promise} - A promise that resolves to the fetched pressure data.
 */
import queryBigQuery from './BigQueryService';


const fetchDevicesData = async (users = []) => {
  const query = `SELECT * FROM device WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchBadgesData = async (users = []) => {
  const query = `SELECT * FROM badges WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchHeartRateIntradayData = async (users = []) => {
  const query = `SELECT date, AVG(value) AS avg_heart_rate FROM heart_rate WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchHeartRateZonesData = async (users = []) => {
  const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchHrvIntradayData = async (users = []) => {
  const query = `SELECT date, AVG(rmssd) AS avg_rmssd FROM hrv_intraday WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchSleepData = async (users = []) => {
  const query = `SELECT date, AVG(minutes_asleep) AS avg_minutes_asleep FROM sleep WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

const fetchSleepSummaryData = async (users = []) => {
  const query = `SELECT date, SUM(total_minutes_asleep) AS total_minutes_asleep FROM sleep_summary WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
  ` GROUP BY date ORDER BY date;`;
  return await queryBigQuery(query);
};

// const fetchHeartRateZones = async (users = []) => {
//   const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
//   ` GROUP BY date ORDER BY date;`;
//   return await queryBigQuery(query);
// };

// const fetchHeartRateZones = async (users = []) => {
//   const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
//   ` GROUP BY date ORDER BY date;`;
//   return await queryBigQuery(query);
// };

// const fetchHeartRateZones = async (users = []) => {
//   const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
//   ` GROUP BY date ORDER BY date;`;
//   return await queryBigQuery(query);
// };

// const fetchHeartRateZones = async (users = []) => {
//   const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
//   ` GROUP BY date ORDER BY date;`;
//   return await queryBigQuery(query);
// };

// const fetchHeartRateZones = async (users = []) => {
//   const query = `SELECT date, SUM(out_of_range_minutes) AS total_out_of_range_minutes FROM heart_rate_zones WHERE id IN (${users.map(user => `'${user.uid}'`).join(', ')})`
//   ` GROUP BY date ORDER BY date;`;
//   return await queryBigQuery(query);
// };

export {
  fetchDevicesData,
  fetchBadgesData,
  fetchHeartRateIntradayData,
  fetchHeartRateZonesData,
  fetchHrvIntradayData,
  fetchSleepData,
  fetchSleepSummaryData,
};