import React, { Fragment, useEffect } from 'react';
import { useAuthContext } from './AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { completeRegsistration, getCurrentUserDocument, setFitbitAuthData } from './AuthUtils';

function FitbitCallback(props) {
  const { currentUser, loading, userJamasp } = useAuthContext();
  const [localLoading, setLocalLoading] = React.useState(false);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [error, setError] = React.useState(null);

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  // Get the code and state from the URL
  const code = new URLSearchParams(window.location.search).get('code');
  const state = new URLSearchParams(window.location.search).get('state');
  //console.log("Code and state", code, state);

  useEffect(() => {
    if (loading || localLoading) {
      return;
    }
    if (userJamasp?.fitbitData?.access_token) {
      console.log("User has fitbit access token", userJamasp?.fitbitData?.access_token);
      addAlert("success", "Fitbit authorization completed successfully");
      navigate("/user");
    }
    if (userJamasp?.fitbitRequest === undefined) {
      setLocalLoading(false);
      addAlert("error", "Fitbit authorization failed");
      redirectBackToRegistration().then( () => {
        console.log("FitbitCallback: RedirectBackToRegistration (%s) , currentUser : %o, userJamasp : %o, localLoading : %o", loading, currentUser, userJamasp, localLoading);
      });
    }
    console.log("FitbitCallback: (%s) , currentUser : %o, userJamasp : %o, localLoading : %o", loading, currentUser, userJamasp, localLoading);

  }, [currentUser, loading, userJamasp, localLoading]);

  const redirectBackToRegistration = async () => {
    setLocalLoading(true);
    if (window.localStorage.getItem("invitationID")) {
      navigate("/auth/register/" + window.localStorage.getItem("invitationID"));
    } else {
      const invId = await getCurrentUserDocument()?.data()?.invitationId;
      if (!!invId) {
        navigate("/auth/register/" + invId);
      } else {
        setError("Error in your process please try your registration page again, or contact support");
      }
    }
    setLocalLoading(false);
  }

  /**
   * A function to handle the complete Fitbit authorization process.
   * 
   * @return {Promise<void>} 
   */
  const handleCompleteFitbitAuth = async () => {
    if (loading || localLoading) {
      return;
    }
    console.log("handleCompleteFitbitAuth");
    setLocalLoading(true);
    if (!agreeTerms) {
      addAlert("error", "Please agree to the terms and conditions");
      setLocalLoading(false);
      return;
    }
    // check if user is logged in and code and state are present
    if (!currentUser || !code) {
      setLocalLoading(false);
      addAlert("error", "Fitbit authorization failed");
      redirectBackToRegistration();
      return;
    }
    // user is logged in and we have code and state
    else {
      console.log("User is logged in");
      console.log("Code and state are present", code, state);
      /**
       * get fitbit access token from cloud function
      */
      const handleAuthorizationRedirect = httpsCallable(functions, 'handleAuthorizationRedirect');
      const res = await handleAuthorizationRedirect({ code: code, state: state });
      console.log("Authorization result : %o", res);
      if (res?.data?.fitbitData?.access_token) {
        // fitbit access token received successfully.
        // complete registration stuff
        const inv = await completeRegsistration(currentUser);
        console.log("Registration completed", inv);

        setLocalLoading(false);
        addAlert("success", "Fitbit authorization completed successfully");
        //navigate("/auth/register/" + res?.data?.invitationId);
        navigate("/user");
        // completed getting fitbit access token from cloud function
      }

      /**
       * 
       * get fitbit access token from cloud run
       * /
      const fitbitRequestData = userJamasp?.fitbitRequest;
      if(!fitbitRequestData || fitbitRequestData?.state!==state){
        addAlert("error", "States don't match. Please try again.");
        setLocalLoading(false);
        navigate("/auth/register/" + fitbitRequestData?.invitationId || userJamasp?.invitationId);
      }else{
        const res = await getOrRenewAccessToken("get", code, fitbitRequestData?.verifier);
        console.log("Fitbit access token:", res);
        if(res?.access_token){
          setFitbitAuthData(userJamasp.uid, res);
          completeRegsistration(currentUser);
        }else{
          addAlert("error", "Error in getting fitbit access token. Please try again.");
          navigate("/auth/register/" + fitbitRequestData?.invitationId || userJamasp?.invitationId);
        }
      }
      // getting fitbit access token from cloud run completed here
      */

      // Failed getting fitbit access token
      else {
        setLocalLoading(false);
        addAlert("error", "Fitbit authorization failed");
        redirectBackToRegistration();
      }
    }
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card>
        <CardHeader title="Fitbit constent form" />
        <CardContent>
          {(loading || localLoading) && (
            <Box  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}
          <Typography variant="body1">
            Hereby I give my consent to share my fitbit wearable data with Jamasp.
            I understand that my data will be used for research purposes and will be kept confidential.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            I understand that I can withdraw my consent at any time.
          </Typography>
          <FormControlLabel sx={{ mt: 3 }}
            control={<Checkbox value="accept" required
              onChange={() => setAgreeTerms(!agreeTerms)} color="primary" checked={agreeTerms} />}
            label="By clicking this box, I accept the Terms of Service and Privacy Policy."
          />
        </CardContent>
        <CardActions>
          <Button disabled={!agreeTerms || !error || !currentUser || loading || localLoading} variant="contained" color="primary" onClick={() => handleCompleteFitbitAuth()}>Complete Authorization</Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default FitbitCallback;