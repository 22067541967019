import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../auth/AuthContext';
import { useAlert } from '../../context/AlertContext';
import { fetchHeartRateIntradayData, fetchHeartRateZonesData, fetchHrvIntradayData, fetchSleepData, fetchSleepSummaryData } from '../../services/SensorDataService';

function ShowUser(props) {
  const { userId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  // theUser is the user object from datastore and gotten from the db by params
  const [userProfile, setUserProfile] = React.useState({});
  // Alerts to display to the user
  const { addAlert } = useAlert();

  // User devices
  const [userDevices, setUserDevices] = React.useState([]);
  // User object from the AuthContext set in App.js
  const { currentUser, loading, userJamasp } = useAuthContext();


  /**
   * Retrieves user profile from Fitbit API
   *
   * @return {void}
   */
  useEffect(() => {
    setIsLoading(true);
    // fettchFitBitUser(userId).then((user) => {
    //   //console.log('showUser: userFound:', user);
    //   if (isArray(user)) {
    //     user = user[0];
    //   }
    //   setTheUser(user);
    //   try {
    //     axios.get('https://api.fitbit.com/1/user/-/profile.json', {
    //       headers: {
    //         Authorization: `Bearer ${user.fitbitData.access_token}`
    //       }
    //     }).then((result) => {
    //       if (result.status === 200 && result?.data?.user) {
    //         //console.log(result.data.user);
    //         // update the user profile
    //         setUserProfile(result.data.user);
    //         axios.get('https://api.fitbit.com/1/user/-/devices.json', {
    //           headers: {
    //             Authorization: `Bearer ${user.fitbitData.access_token}`
    //           }
    //         }).then((result) => {
    //           if (result.status === 200 && result?.data) {
    //             //console.log(result.data);
    //             // update the user profile
    //             setUserDevices(result.data);
    //           }
    //         }).catch((error) => {
    //           console.error(error);
    //         });
    //       }
    //     }).catch((error) => {
    //       console.log(error);
    //     }
    //     );
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }).catch((error) => {
    //   console.log(error);
    //   setAlert({ type: 'error', message: error.message });
    // });
    setIsLoading(false);
  }, [userId]);

  /**
   * After user has loaded it will load the charts
   */
  useEffect(() => {
    console.log("userId: ",);
    const fetchUserData = async (userId) => {
      if (!userId) return null;
      //TODO: if user not found we should return null and ultimately redirect to 404
      if (!userJamasp || !userJamasp?.inviteeUID) {
        addAlert({ type: 'error', message: 'User not found' });
        return null;
      }
      const heartRateIntradayData = await fetchHeartRateIntradayData(userJamasp?.inviteeUID);
      const heartRateZonesData = await fetchHeartRateZonesData(userJamasp?.inviteeUID);
      const hrvIntradayData = await fetchHrvIntradayData(userJamasp?.inviteeUID);
      const sleepData = await fetchSleepData(userJamasp?.inviteeUID);
      const sleepSummaryData = await fetchSleepSummaryData(userJamasp?.inviteeUID);


      console.log("heartRateIntradayData: ", heartRateIntradayData);
      console.log("heartRateZonesData: ", heartRateZonesData);
      console.log("hrvIntradayData: ", hrvIntradayData);
      console.log("sleepData: ", sleepData);
      console.log("sleepSummaryData: ", sleepSummaryData);
    };
    setIsLoading(true);
    fetchUserData();
    setIsLoading(false);


    // return if theUser is null
    // if (theUser === null || theUser === undefined)
    //   return;
    // const prof = fetchUserProfileNow(theUser).then((result) => {
    //   console.log("result profNOW: ", result);
    // }).catch((error) => {
    //   console.log(error);
    // }
    // );
    // console.log("prof: ", prof);
    // fetchUserProfile(theUser).then((result) => {
    //   console.log("result: ", result);
    //   if (result.status)
    //     setUserProfile(result.data);
    // }).catch((error) => {
    //   console.log(error);
    // });
  }, [userJamasp]);


  function refreshFitbitTokenHandle() {
    console.log("refreshFitbitToken: ", userId);
    setIsLoading(true);
    // refreshFitbitToken(theUser.uid).then((result) => {
    //   console.log("refreshFitbitToken: ", result);
    //   setUserProfile(result?.data?.user);
    // }).catch((error) => {
    //   console.log(error);
    // });
    addAlert({ type: 'success', message: 'Refreshing Fitbit Token' });
    setIsLoading(false);
  }

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <Typography variant="h4" component="h1" gutterBottom>Showing user {userJamasp?.name}</Typography>

      <Box>
        <Button variant="contained" color="primary" onClick={refreshFitbitTokenHandle}>Refresh Fitbit Token</Button>
      </Box>
      {!isLoading && (
        <Box>
          <Typography variant="h5" component="h2" gutterBottom>User: {userJamasp?.name}</Typography>
          <Typography variant="h5" component="h2" gutterBottom>Email: {userJamasp?.email}</Typography>
          <Typography variant="h5" component="h2" gutterBottom>Fitbit ID: {userJamasp?.fitbitData?.user_id}</Typography>
          <Typography variant="h5" component="h2" gutterBottom>Last Token Updated At:
            {(!!userJamasp?.fitbitData?.timestamp && !!userJamasp?.fitbitData?.timestamp?.toDate()) ?
              userJamasp?.fitbitData?.timestamp?.toDate()?.toLocaleString() : 'Undefined'}
          </Typography>
          {!!userProfile && (
            Object.entries(userProfile).map(([key, value]) => {
              if (typeof value === 'object' && value !== null) { // it's a nested object
                if (key === 'topBadges') { // Top badges are shown differently
                  return (
                    <div key={key}>
                      <Typography key={key} variant="body1" component="p" gutterBottom>
                        TOP {key}:
                      </Typography>
                      {value.map((badge) => {
                        console.log("inloop deplsy", badge);
                        return (
                          <Typography key={badge.badgeGradientEndColor} variant="body1" component="p">
                            <img src={badge.image50px} alt={badge.name} /> {badge.shortName} : {badge.name} : {badge?.category} : {badge.badgeType} : {badge.description} : {badge.earnedMessage}
                          </Typography>
                        )
                      })}
                    </div>
                  )
                } else { // other nested badges
                  return (
                    <div key={key}>
                      <Typography variant="body1" component="p" gutterBottom>
                        {key}:
                      </Typography>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <Typography key={subKey} variant="body1" component="p" gutterBottom>
                          {subKey}: {subValue}
                        </Typography>
                      ))}
                    </div>
                  );
                }
              } else { // it's a simple key-value pair badge
                return (
                  <Typography key={key} variant="body1" component="p" gutterBottom>
                    {key}: {value}
                  </Typography>
                );
              }
            })
          )}
        </Box>
      )}

      {!!userDevices && userDevices.length > 0 && (
        <>
          {/* Showing user devices */}
          <Typography variant="h5" component="h2" gutterBottom>Devices:</Typography>
          {userDevices.map((device) => {
            return (
              <Box key={device.deviceVersion}>
                <Typography key={device.deviceVersion} variant="h5" component="h2" >
                  {device.deviceVersion}
                </Typography>
                <Typography key={device.type} variant="body1" component="p">
                  Type: {device.type}
                </Typography>
                <Typography key={device.battery} variant="body1" component="p">
                  Battery: {device.battery} | Battery Level: {device.batteryLevel}
                </Typography>
                <Typography key={device.lastSyncTime} variant="body1" component="p" fontWeight={'bold'}>
                  Last Sync Time: {device.lastSyncTime}
                </Typography>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};
export default ShowUser;