import { Box } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';

export default function About() {
  const [data, setData] = useState("");
  const url = '/about.md';

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        // console.log(text);
        setData(text);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box sx={{ mt: 5, m: 1 }}>
      <ReactMarkdown>{data}</ReactMarkdown>
      {/* {data && <div dangerouslySetInnerHTML={{ __html: data }} />} */}
    </Box>
  );
}