//TODO: Improve this function to be more generic
const checkAllTrue = (...objects) => {
  console.log(objects);
  if (objects===undefined || objects===null)
    throw new Error("Object is undefined");

  if (Array.isArray(objects)) {
    for (const obj of objects) {
      console.log('obj: %s',!!obj);
      if (!obj) return false;
    }
    console.log("after 1st if");
    return true;
  } else if (typeof objects === 'object') {
    for (const key in objects) {
      console.log('key: %b',!!objects[key]);
      if (!objects[key]) return false;
    }
    console.log("after 3rd if");
    return true;
  } else if(objects.length>0){
    console.log(" single obj : ",!!objects[0]);
    return !!objects[0];
  } else {
    console.log(objects)
    return false;
  }
};

  /**
   * Handles generating and downloading a JSON file.
   *
   * @param {Array} data - The data to be converted to JSON and downloaded.
   * @param {string} fileName - The name of the downloaded file. If empty, defaults to "output.json".
   */
  const handleGenerateJsonDownload = (data, fileName) => {
    if (data !== null && data !== undefined && data.length > 0) {
      // create file in browser
      if (fileName === '') {
        fileName = 'output.json';
        const json = JSON.stringify(data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      }
    };
  };


export { checkAllTrue, handleGenerateJsonDownload };

