import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { auth } from '../firebase';
import { useAuthContext } from './AuthContext';

// reCAPTCHA size
const recaptchaSize = 'normal';

const PhoneVerify = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  const { linkWithPhoneNumber, verifyPhoneNumber, currentUser } = useAuthContext();

  const { addAlert } = useAlert();
  const navigate = useNavigate();


  /* Initializes the ApplicationVerifier.
  * @param {string} submitButtonId The ID of the DOM element of the button to
  *     which we attach the invisible reCAPTCHA. This is required even in visible
  *     mode.
  */
  function makeApplicationVerifier(submitButtonId) {
    const container =
      recaptchaSize === 'invisible' ? submitButtonId : 'recaptcha-container';
    window.recaptchaVerifier = new RecaptchaVerifier(auth, container, {
      'size': (recaptchaSize === 'invisible' ? 'invisible' : 'normal'),
      'callback': (response) => {
        console.log("makeApplicationVerifier : %o", response);
      },
      'expired-callback': () => {
        console.log('expired-callback');
        // Response expired. Ask user to solve reCAPTCHA again.
        addAlert('error', 'Response expired. Ask user to solve reCAPTCHA again.');

      },
      'error-callback': (error) => {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
      //todo: handle error
    });
    return window.recaptchaVerifier;
  }

  const clearApplicationVerifier = () => {
    const recaptchaVerifier = window.recaptchaVerifier;
    console.log("clearApplicationVerifier : %o : %o", recaptchaVerifier, window.recaptchaVerifier);
    if (recaptchaVerifier !== null) {
      recaptchaVerifier?.clear();
      setRecaptchaVerifier(null);
    }
    if (window.recaptchaVerifier !== null) window.recaptchaVerifier?.clear();
  };

  // Initialize reCAPTCHA}
  useEffect(() => {
    if(!currentUser)
      navigate('/', { replace: true });

    window.recaptchaVerifier = makeApplicationVerifier('phone-verify-submit');
  }, []);

  const handleSendCode = async (event) => {
    event.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    const result = await linkWithPhoneNumber(currentUser,phoneNumber, appVerifier);
    setConfirmationResult(result);
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    const result = await verifyPhoneNumber(confirmationResult, code);
    console.log("handleVerifyCode , ressult : %o", result);
    if (result) {
      addAlert('success', 'Phone number verified!');
      navigate('/user/profile', { replace: true });
    }

  };
  
  const handleAgreeTerms = (event) => {
    setAgreed(event.target.checked);
  }


  return (
    <Fragment>
      <Box sx={{ mt: 5, m: 1 }}>
        <Typography variant="h3">Phone Login</Typography>
        <Typography variant="subtitle">Please Enter Your Phone To Complete Your Registration</Typography>
        <Box component={"form"} onSubmit={handleSendCode} sx={{ m: 1, '& > :not(style)': { m: 1, width: '25ch' } }} >
          <TextField
            margin="normal"
            required
            disabled= {confirmationResult}
            fullWidth
            id="phone"
            label="Phone Number (e.g. +1 123 456 7890)"
            name="phone"
            autoComplete="phone"
            pattern="[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*"
            autoFocus
            type='tel'
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
          />

          <FormControlLabel
            control={<Checkbox value="accept" required onChange={handleAgreeTerms} color="primary" />}
            label="By clicking this box, I accept the Terms of Service and Privacy Policy and I consent to Jamasp's use of my contact information for sending me messages. Message & data rates may apply"
          />
          <Button
            type="submit"
            disabled={!agreed}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send verification code
          </Button>
          <br />
          <Typography variant="subtitle">We will send you an SMS to confirm your phone number. Message & data rates may apply.</Typography>
          <Typography variant="subtitle">By signing in, you agree to our <Link to="/terms" target='_blank'>Terms of Service and Privacy Policy</Link>.</Typography>
        </Box>

        {confirmationResult && (
        <Box component={"form"} onSubmit={handleVerifyCode} sx={{ m: 1, '& > :not(style)': { m: 1, width: '25ch' } }}>
          <TextField
            margin="normal"
            type="text"
            required
            fullWidth
            id="verificationCode"
            label="Verification Code"
            name="verificationCode"
            autoComplete="verificationCode"
            autoFocus
            pattern="^[0-9]{6}$"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <Button id="phone-verify-submit" type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Verify code</Button>
        </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default PhoneVerify;