import { CloudDownload, Download as DownloadIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { Fragment, useState } from 'react';
import { fitbitBucket } from '../../firebase';
import { convertDateToReadableString } from '../utils/utils';


/**
 * DownloadHistoryPanel component for managing sensor downloads fetches.
 *
 * @param {Object} props - The component props.
 * @param {string} props.project - The project name.
 * @param {Array} props.fetchJobHistory - The fetch job history.
 * @returns {JSX.Element} The DownloadPanel component.
 */
function DownloadHistoryPanel(props) {
  const { project, fetchJobsHistory } = props;
  const [openArray, setOpenArray] = useState([]);
  console.log(project, fetchJobsHistory);

  /**
   * Handles the click event on a list item.
   *
   * @param {number} index - The index of the clicked list item.
   */
  const handleListItemClick = (index) => {
    setOpenArray((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  /**
   * Handles the download of a file.
   *
   * @param {string} outputFile - The name of the output file.
   */
  const handleDownload = (outputFile) => {
    const fileRef = ref(fitbitBucket, outputFile);
    getDownloadURL(fileRef)
      .then((url) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = outputFile;
        a.click();
      })
      .catch((error) => {
        console.error("Error getting download URL: ", error);
      });
  };

  const handleFHIRConversion = (outputFile) => {
    const fileRef = ref(fitbitBucket, outputFile);
    // load the file
    getDownloadURL(fileRef).then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'application/json';
      xhr.open('GET', url);
      xhr.send();
      xhr.onload = function () {
        if (xhr.status === 200) {
          // The request was successful
          var data = JSON.parse(xhr.responseText);
          console.log(data);
        } else {
          // The request failed
          console.error(xhr.statusText);
        }
      };

    }).catch((err) => {
      console.error("Error getting download URL: ", err);
    });
  }


  /**
   *  Render
   */
  return (
    <Fragment>
      <Typography variant="h4" gutterBottom>
        Download History
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          p: 2
        }}
      >
        <List
          sx={{ width: '100%', mx: 4, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Download History
            </ListSubheader>
          }
        >
          {fetchJobsHistory.map((download, index) => {
            const { outputFile, startedAt, downloadSettings } = download;
            console.log("downloadSettings: ", downloadSettings)
            let earliestIntradayFetch = null;
            const downloadDates = downloadSettings.filter((setting) => setting.enabled === true)
              .map((setting) => {
                if (setting.sensorId.indexOf("Intraday") > -1) {
                  if (earliestIntradayFetch === null) {
                    earliestIntradayFetch = new Date(setting.arguments?.["start-date"]);
                  } else {
                    if (earliestIntradayFetch?.getTime() > new Date(setting.arguments?.["start-date"])?.getTime()) {
                      earliestIntradayFetch = new Date(setting.arguments?.["start-date"]);
                    }
                  }
                }
                const item = {
                  sensorId: setting.sensorId,
                  arguments: JSON.stringify(setting.arguments, null, 2),
                };
                if (setting.parameters?.length > 0) {
                  item.parameters = JSON.stringify(setting.parameters, null, 2);
                }
                return item;
              });

            console.log("downloadDates: ", downloadDates);

            return (
              <div key={index}>
                <ListItemButton onClick={() => handleListItemClick(index)}>
                  <ListItemIcon>
                    <CloudDownload />
                  </ListItemIcon>
                  <ListItemText primary={index +  " at " + convertDateToReadableString(startedAt)} secondary={earliestIntradayFetch + " : " + earliestIntradayFetch?.toLocaleString()} />
                  {openArray[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openArray[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleDownload(outputFile)}>
                      <ListItemIcon>
                        <DownloadIcon />
                      </ListItemIcon>
                      <ListItemText primary={outputFile} secondary={JSON.stringify(downloadDates, null, 2)} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleFHIRConversion(outputFile)}>
                      <ListItemIcon>
                        <ChangeCircleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={outputFile} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </div>
            )
          })}
        </List>
      </Box>
    </Fragment>
  )
}

export default DownloadHistoryPanel;

