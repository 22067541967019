import { Box, Button, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { functions } from '../../firebase';
import { auth } from '../../firebase';
import { useAlert } from '../../context/AlertContext';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { fetchFitBitUsers } from '../../auth/AuthUtils';

const Tests = () => {
  const [users, setUsers] = useState([]);
  const [authUsers, setAuthUsers] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [introspectsLoading, setIntrospectsLoading] = useState([]);
  // { introspectsLoading: { userid: null, loading: false, error: null, data: null } }

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => () => {
    if (!auth?.currentUser)
      return;
    auth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        console.log(idTokenResult);
        console.log("claimes: ", idTokenResult.claims);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authUsers]);
  // Fetch users on mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const getAllUsers = httpsCallable(functions, 'getAllUsers');
        getAllUsers()
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            console.log(result);
            setAuthUsers(data.users);
            // ...
          })
          .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            // ...)
            console.log(code, message, details);
          });
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();

    fetchFitBitUsers().then((users) => {
      console.log("users", users);
      setUsers(users);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  // Refresh users data
  useEffect(() => {
    //console.log("users", users);
    authUsers.forEach((user) => {
      refreshUsersData(user);
    });
  }, [authUsers]);


  const refreshUsersData = async function (user) {
    try {
      console.log("refreshUsersData", user);
    } catch (error) {
      console.log(error);
    }
  }

  function makeAdmin(userId) {
    console.log("makeAdmin", userId);
    const makeAdmin = httpsCallable(functions, 'toggleUserRole');
    makeAdmin({ uid: userId, role: 'admin' })
      .then(result => {
        console.log(result);
        //navigate('/admin/users');
      })
      .catch(error => {
        console.log(error);
      })
    //
  }

  // Admin function test
  const handleAdminFunc = () => {
    // const adminFunc = httpsCallable(functions, 'addNumbers');
    // adminFunc({ firstNumber: 3, secondNumber: 4 }).then((result) => {
    //   console.log(result);

    // }).catch((error) => {
    //   console.error(error);
    // });
  }

  // Callback test
  async function justAnAsyncFunction(onComplete) {
    let result = "Started at " + new Date();
    setTimeout(() => {
      // Code to be executed after 1 second
      result += " and finished at " + new Date();
      onComplete(result);
    }, 1000);
  }

  async function handleIntrospect(user, onComplete) {
    console.log("handleIntrospect", user?.uid);
    const introspect = httpsCallable(functions, 'introspectToken');
    introspect({ uid: user.uid }).then((result) => {
      console.log(result);
      onComplete(result);
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleGetProfile = async (userId) => {
    try {
      console.log("refreshUsersData", userId);
      const getFitbitDataFunction = httpsCallable(functions, 'getFitbitData');
      getFitbitDataFunction({ uid: userId })
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  const handleCreateFile = async () => {
    try {
      const createFileFunction = httpsCallable(functions, 'createFile');
      createFileFunction().then((result) => {
        console.log(result);
      }).catch((err) => {
        console.error(err);
      })
    } catch (err) {
      console.log(err);
    }
  }

  const handleTestCreateFileFolders = async (onComplete) => {
    try {
      const createFileFoldersFunction = httpsCallable(functions, 'testCreateFileAndFolders');
      createFileFoldersFunction().then(
        (result) => {
          console.log(result);
          if (!!onComplete)
            onComplete();
        }
      ).catch((err) => {
        console.error(err);
      })

    } catch (err) {
      console.log(err);
    }
  }

  const testBigQuery = async () => {
    try{
      const query = "select * from fitbit_bigquery.activities_steps where @id = id";
      const params = { id: "t23kBodEWeOpzgWvOJmgXaRw28N2"};
      const data = { query, params};
      const queryBigQueryFunction = httpsCallable(functions, 'runBigQueryQuery');
      queryBigQueryFunction(data).then(
        (result) => {
          console.log(result);
        }
      ).catch((err) => {
        console.error(err);
      });


    }catch(err){
      console.log(err);
    }
  }

  return (
    <Box>
      <Typography variant="h1" component="h1" gutterBottom>Users</Typography>
      <List>
        {authUsers.map((user) => (
          <ListItem
            key={user.uid}
            disablePadding
            secondaryAction={<Button edge="end" variant="contained" color="success" onClick={() => makeAdmin(user.uid)}>MakeAdmin</Button>}
          >
            <ListItemButton component={Link} to={`/admin/users/${user.uid}`} >
              <ListItemText primary={user.uid} secondary={user.email + ' | ' + console.log(user?.customClaims === undefined) +
                user?.customClaims === undefined ? 'No Data' : user?.customClaims?.role + ' | admin: ' + user?.customClaims?.admin} />
            </ListItemButton>
          </ListItem>

        ))}
      </List>
      <Typography variant="h3" component="h3" gutterBottom>Jamasp Users</Typography>
      <List>
        {users.map((user) => {
          // check if it exsit in introspectsLoading and if it is loading
          console.log(introspectsLoading);
          if (introspectsLoading.filter((introspect) => introspect.userid === user.uid).length < 1) {
            introspectsLoading.push({ userid: user.uid, loading: false, error: null, data: null });
          }
          console.log("introspectsLoading", introspectsLoading.filter((introspect) => introspect.userid === user.uid));
          return (
            <ListItem
              key={user.uid}
              disablePadding
              secondaryAction={
                <Stack direction="row" spacing={2}>
                  <Button edge="end" variant="contained" color="success" onClick={() => handleGetProfile(user.uid)}
                  >Refresh</Button>
                  <LoadingButton
                    loading={introspectsLoading.filter((introspect) => introspect.userid === user.uid)[0].loading}
                    onClick={() => {
                      setIntrospectsLoading(introspectsLoading.map((introspect) => {
                        if (introspect.userid === user.uid) {
                          introspect.loading = true;
                        }
                        return introspect;
                      }
                      ));
                      handleIntrospect(user, ((result) => {
                        console.log(result);
                        setIntrospectsLoading(introspectsLoading.map((introspect) => {
                          if (introspect.userid === user.uid) {
                            introspect.loading = false;
                            introspect.data = result.data;
                          }
                          return introspect;
                        }
                        ));
                      }));
                    }}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Introspect
                  </LoadingButton>

                </Stack>
              }
            >
              <ListItemButton component={Link} to={`/admin/users/${user.uid}`} >
                <ListItemText primary={user.name} />
              </ListItemButton>
            </ListItem>
          )
        }
        )}
      </List>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        {process.env.NODE_ENV === 'development' && (
          <div>
            <Button onClick={() => addAlert('success', 'success at + ' + new Date())}>Success</Button>
            <Button onClick={() => addAlert('error', 'error at + ' + new Date())}>error</Button>
          </div>
        )}
        <Button onClick={() => handleAdminFunc()}>Admin Func Test</Button>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => navigate('/admin/users')}>Users</Button>
        <LoadingButton
          loading={buttonLoading}
          onClick={() => {
            setButtonLoading(true);
            justAnAsyncFunction((result) => {
              console.log(result);
              setButtonLoading(false);
            });
          }}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
        >
          Callback Test
        </LoadingButton>
        <Button onClick={() => handleCreateFile()} variant="contained">Create File</Button>
        <LoadingButton
          loading={btnLoading}
          onClick={() => {
            setBtnLoading(true);
            handleTestCreateFileFolders((result) => {
              console.log(result);
            });
            setBtnLoading(false);
          }}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained">TetstCreateFileFolders</LoadingButton>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button onClick={() => testBigQuery()}>Test BiqQuery</Button>
      </Stack>
    </Box>
  );
};
export default Tests;