
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

//const bigquery = new BigQuery();

/**
 * Executes a BigQuery query with the provided query and parameters.
 *
 * @param {string} query - The BigQuery query to execute.
 * @param {object} params - The parameters to be used in the query.
 * @returns {Promise<any>} - A promise that resolves with the result of the query.
 */
const queryBigQuery = async (query, params) => {
  const queryFunction = httpsCallable(functions, 'runBigQueryQuery');
  const [result] = await queryFunction({ query, params }).catch((err) => {
    console.error(" error in queryBigQuery: ",err);
  });
  console.log("result", result);
  return result;
};

export default queryBigQuery;