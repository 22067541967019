import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Box sx={{ mt: 5, m: 1 }}>
      <Typography variant="h5" gutterBottom>Not Found</Typography>
      <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden', opacity: 0.9, m: 3, p: 3 }} >
        <Typography variant='body1' >The page you are looking for does not exist...</Typography>
      </Paper>
    </Box>
  );
};  

export default NotFound;