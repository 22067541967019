import React from 'react';
import { Box, Button, Container, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { Timestamp, addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../auth/AuthContext';
import { db } from '../../firebase';
import { useAlert } from '../../context/AlertContext';
import { generateInviteCode, generateParticipantUID, updateGlobalInvitees, createNewProjectInvitee } from '../../auth/AuthUtils';


const steps = ['Select a name', 'Invite existing users', 'Invite new participants', 'Settings' , 'Review'];

/**
 * Renders the form for creating a new project.
 * @returns {JSX.Element}
 */
const NewProject = () => {
  const { currentUser, loading, userJamasp } = useAuthContext();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [projectName, setProjectName] = React.useState('');
  const [invitedNewUsers, setInvitedNewUsers] = React.useState([]);
  const [invitedExistingUsers, setInvitedExistingUsers] = React.useState([]);

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleInviteNewUser = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.target);
    const newInvitee = createNewProjectInvitee({
      uid: data.get('uid'),
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone'),
      role: data.get('role')
    });
    setInvitedNewUsers(invitedNewUsers => [...invitedNewUsers, newInvitee]);
    console.log("Invite new users. event.data: %o", data);
  }


  const handleInviteUser = (event) => {
    event.preventDefault();
    console.log("Invite user. event: %o", event);
  }

  /**
   * Handles the action of creating a new project.
   * 
   */
  const handleCreateProject = async() => {
    // Add the project to Firestore
    const projectDoc = {
      name: projectName,
      devices: [],
      sensors: [],
      settings: {
        detailLevel: '1min',
        dateRange: {
          from: Timestamp.fromMillis(new Date(2021, 1, 29).getTime()),
          to: Timestamp.fromMillis(new Date().getTime()),
        },
        enabled: true,
      },
      downloadSettings: [],
      invitedNewUsers: invitedNewUsers,
      invitedExistingUsers: invitedExistingUsers,
      invitationWindow: {
        from: Timestamp.fromMillis(new Date(2024, 1, 29).getTime()),
        to: Timestamp.fromMillis(new Date(2025, 1, 29).getTime())
      },
      cronSettings: [],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: currentUser?.uid
    };
    //console.log(projectDoc);

    addDoc(collection(db, "projects"), projectDoc).then((docRef) => {
      const projectId = docRef.id;
      const updateProjectDoc = doc(db, "projects", projectId);
      updateDoc(updateProjectDoc, {
        id: projectId,
      }).then(() => {
        //console.log("Project updated with ID:", projectId);
        addAlert('success', `Project with id: ${projectId} created!`);
        //navigate(`/admin/showProject/${projectId}`);
        updateGlobalInvitees(projectDoc, projectId);
        addAlert('success', `Project ${projectName} with id: ${projectId} updated successfully`);
        navigate(`/admin/projects/${projectId}`);
      }).catch((error) => {
        //TODO: Handle error properly
        console.error("Error updating project:", error);
        addAlert('error', `Project with id: ${projectId} could not be updated!`);
        return null;
      });
      // Redirect to the project page

      return projectId;
    });

  };

  // Render the form
  return (
    <Container component="main" maxWidth="xl" >
      <Typography variant="h3">Create Project</Typography>
      <Typography variant="body1">Create a new project and add devices to it.</Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={() => navigate("/admin/projects")}>View Project</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}  | {steps[activeStep]}</Typography>
          {activeStep === 0 && (
            <Box key={"step0Box"} sx={{ mt: 1 }}>
              <Typography variant="body1">Select a name for your project.</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="projectName"
                label="Project Name"
                name="name"
                autoComplete="Project Name"
                autoFocus
                onChange={(e) => setProjectName(e.target.value)}
                value={projectName}
              />
              <Button
                onClick={() => setProjectName('')}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset
              </Button>
            </Box>
          )}
          {activeStep === 1 && (
            <Box key={"step1Box"} sx={{ mt: 1 }}>
              <Typography key="step1Typography" variant="body1">Invite existing users to the project.</Typography>
              {invitedExistingUsers.map((user) => (
                <Box key={user.uid+'-invitedExistingUserBox'} sx={{ mt: 1 }} >
                  <Typography variant="body1">
                    <b>{user.name}</b> {user.email} | {user.uid}
                  </Typography>
                  <Typography variant="body1">)
                  </Typography>
                </Box>
              ))}
              <Box key={"invitingExisitingUserBox"} component={'form'} onSubmit={handleInviteUser} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="inviteExistingUserByEmailInput"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add user to projects invitees.
                </Button>
              </Box>
            </Box>
          )}
          {activeStep === 2 && (
            <Box key={"step2Box"} sx={{ mt: 1 }}>
              <Typography key="step2Typography" variant="body1">Invite new users as participant to the project.</Typography>
              {invitedNewUsers.map((user) => (
                <Box key={user.uid+'-invitedNewUserBox'} sx={{ mt: 1 }}>
                  <Typography variant="body1">
                    <b>{user.name}</b> {user.email} | {user.uid} | {user.phone} | {user.role}
                  </Typography>
                  <Typography variant="body1">
                  </Typography>
                </Box>
              ))}
              <Box key="invitingNewUserFormBox" component={'form'} onSubmit={handleInviteNewUser} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="uid"
                  label="Participant's UID"
                  name="uid"
                  autoComplete="off"
                  defaultValue={generateParticipantUID()}
                />
                <TextField
                  margin="normal"
                  type="email"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="off"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="name"
                  autoComplete="fullName"
                />
                <TextField
                  margin="normal"
                  type="tel"
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  autoComplete="off"
                  pattern="\+1[0-9]{10}"
                  placeholder="+11234567890"
                  title="Phone number must be in format: +1234567890"
                />
                <TextField
                  margin="normal"
                  type="password"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="off"
                />
                <TextField
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  id="role"
                  label="Role"
                  name="role"
                  autoComplete="role"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add New Participant
                </Button>
              </Box>
            </Box>
          )}
          {activeStep === 3 && (
            <Box key={"step3Box"} sx={{ mt: 1 }}>
              <Typography key="step3Typography" variant="body1">Project Settings</Typography>
                <Typography key="step3Typography2" variant="body1">Set invitation window</Typography>
                <TextField
                  margin="normal"
                  type="date"
                  required
                  fullWidth
                  id="projectSetting_from"
                  label="Invitation Window From"
                  name="from"
                  autoComplete="off"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  type="date"
                  required
                  fullWidth
                  id="projectSetting_to"
                  label="Invitation Window To"
                  name="to"
                  autoComplete="off"
                  autoFocus
                />
            </Box>
          )}
          {activeStep === 4 && (
            <Box key={"step4Box"} sx={{ mt: 1 }}>
              <Typography key="step4Typography" variant="body1">Project Review</Typography>
              {/* Show project here */}

              <Button onClick={handleCreateProject}>Create Project</Button>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button variant='contained' onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}

    </Container>

  );
};

export default NewProject;
