import { Box, Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useAlert } from '../context/AlertContext';
import { Link } from 'react-router-dom';

export default function Home() {
  const [email, setEmail] = useState('');
  const { addAlert } = useAlert();

  return (
    <Fragment>
      <Box
        id="hero"
        sx={(theme) => ({
          //width: '100%',
          // backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
          // backgroundSize: '100% 20%',
          // backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/images/HomePageBackPort.png)' ,
          backgroundSize: 'cover',
          backgroundPositionY: 'center',
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          {/* <Grid container spacing={2}>
            <Grid item xs={3} textAlign={'left'} >
              <img src={'/images/HomePageBack.png'} alt='logo' width={'100%'} />
            </Grid>
            <Grid item xs={9} > */}
              <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '80%' } }}>
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignSelf: 'left',
                    textAlign: 'left',
                  }}
                >
                  Jamasp
                </Typography>
                <Paper sx={{ backgroundColor: '#ffffff44'  , p: 2, borderRadius: 1 }} elevation={0}> 
                <Typography variant="body1" textAlign="left">
                Jamasp is a comprehensive platform designed to streamline the design and deployment of research using wearable devices. Secure, reliable, and user-friendly, Jamasp offers a complete suite of tools for managing participation, monitoring data collection, formatting and storing data from various sensors, and analyzing this data with advanced machine learning and artificial intelligence tools. <br />
                  {/* Subscribe to our newsletter to get the latest updates. */}
                </Typography>
                </Paper>
                {/* <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignSelf="center"
                  spacing={1}
                  useFlexGap
                  sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                >
                  <TextField
                    id="outlined-basic"
                    hiddenLabel
                    size="small"
                    variant="outlined"
                    aria-label="Enter your email address"
                    placeholder="Your email address"
                    inputProps={{
                      autocomplete: 'off',
                      ariaLabel: 'Enter your email address',
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  <Button variant="contained" color="primary" aria-label='Subscribe' onClick={handleSubscribe}>
                    Subscribe
                  </Button>
                </Stack>
                <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
                  By clicking &quot;Start now&quot; you agree to our&nbsp;
                  <Link href="/terms" color="primary">
                    Terms & Conditions
                  </Link>
                  .
                </Typography> */}
              </Stack>

            {/* </Grid>
          </Grid> */}
        </Container>
      </Box>
      <Box
        id="highlights"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          color: 'white',
          bgcolor: '#06090a',
        }}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '80%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
          >
            <img src='/images/HomepageImage.png' alt='HomepageImage' width={'100%'} />
            <Typography variant="body1" sx={{ color: 'grey.400', py: 2 }} textAlign={'left'}>
              Jamasp supports a wide range of research areas, including behavioral and social sciences focusing on testing intervention efficacy and psychophysiology, healthcare research monitoring patient vitals and activity levels, sports science tracking athlete performance, and workplace safety evaluating physical strain and environmental conditions. It also facilitates business and consumer behavior studies, marketing analysis, organizational behavior and management research, teamwork dynamics, cognitive research analyzing mental workload and stress, and fitness and wellness studies. No matter your research needs, Jamasp makes it as simple as possible for you!
            </Typography>
            <Button variant='outlined' sx={{ mb : 2}} color={'secondary'} component={Link} to='/about'>Learn More</Button>
            <Container>
            </Container>

          </Box>
        </Container>
      </Box>
    </Fragment>    
    // <Fragment>
    //   <Box
    //     id="hero"
    //     sx={(theme) => ({
    //       width: '100%',
    //     })}
    //   >
    //     <Container
    //       maxWidth="xl"
    //       sx={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //         mt: 3,
    //         pb: 2,
    //       }}
    //     >
    //       {/* <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
    //         <img src={'/images/logo/android-chrome-512x512.png'} alt='logo' width={'50%'} height={'50%'} />
    //         <Typography variant='body1'>Jamasp is a comprehensive platform designed to streamline the design and deployment of research using wearable devices. Secure, reliable, and user-friendly, Jamasp offers a complete suite of tools for managing participation, monitoring data collection, formatting and storing data from various sensors, and analyzing this data with advanced machine learning and artificial intelligence tools.
    //         </Typography>
    //         <img src='/images/HomepageImage.png' alt='HomepageImage' width={'100%'} height={'100%'} />
    //       </Stack> */}
    //       <Grid container spacing={2}>
    //         <Grid item xs={4} textAlign={'left'} >
    //           <Typography variant='body1' sx={{pb: 1}}>Jamasp is a comprehensive platform designed to streamline the design and deployment of research using wearable devices. Secure, reliable, and user-friendly, Jamasp offers a complete suite of tools for managing participation, monitoring data collection, formatting and storing data from various sensors, and analyzing this data with advanced machine learning and artificial intelligence tools.
    //           </Typography>
    //           <Typography variant='body1' sx={{pb: 3}}>Jamasp supports a wide range of research areas, including behavioral and social sciences focusing on testing intervention efficacy and psychophysiology, healthcare research monitoring patient vitals and activity levels, sports science tracking athlete performance, and workplace safety evaluating physical strain and environmental conditions. It also facilitates business and consumer behavior studies, marketing analysis, organizational behavior and management research, teamwork dynamics, cognitive research analyzing mental workload and stress, and fitness and wellness studies. No matter your research needs, Jamasp makes it as simple as possible for you!
    //           </Typography>
    //           <Stack
    //             direction="row"
    //             justifyContent="flex-end"
    //             alignItems="center"
    //             spacing={2}
    //           >
    //             <Button variant='contained' color='primary' component={Link} to='/about'>Learn More</Button>
    //           </Stack>
    //         </Grid>
    //         <Grid item xs={8} >
    //           <img src='/images/HomepageImage.png' alt='HomepageImage' width={'100%'} />
    //         </Grid>
    //       </Grid>
    //     </Container>
    //   </Box>
    // </Fragment>
  );
}