import React from 'react';
import { Table, TableContainer, TableBody, TableCell, TableHead, Paper, TableRow, IconButton } from '@mui/material';
import {
  Sms as SmsIcon, Delete as DeleteIcon, Edit as EditIcon, Email as EmailIcon,
  Close as CloseIcon, Done as DoneIcon
} from '@mui/icons-material';
import { useAlert } from '../../context/AlertContext'



export default function InviteesTable({ invitees, handleInviteesTableAction }) {
  const { addAlert } = useAlert();

  const handleAction = (action, invitee, onComplete) => {
    console.log(action, invitee);
    handleInviteesTableAction(action, invitee);
    onComplete && onComplete();
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">UID</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">InvitationID</TableCell>
            <TableCell align="right">Step</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitees.map((invitee) => (
            <TableRow
              key={invitee.uid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{invitee.email} {invitee?.emailVerified ? <DoneIcon /> : <CloseIcon />}</TableCell>
              <TableCell >
                {invitee.uid}
              </TableCell>
              <TableCell >{invitee.name}</TableCell>
              <TableCell >{invitee.phone} {invitee?.phoneVerified ? <DoneIcon /> : <CloseIcon />}</TableCell>
              <TableCell >{invitee.invitationID}</TableCell>
              <TableCell >{invitee.registrationStep}</TableCell>
              <TableCell >
                <IconButton aria-label="edit" color="secondary" onClick={() => handleAction('edit', invitee)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="send email" color="primary" onClick={() => handleAction('send', invitee)}>
                  <EmailIcon />
                </IconButton>
                <IconButton aria-label="send sms" color="success" onClick={() => handleAction('sms', invitee, () => addAlert("success", "SMS sent"))}>
                  <SmsIcon />
                </IconButton>
                <IconButton aria-label="delete" color="error" onClick={() => handleAction('delete', invitee)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}