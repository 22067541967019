import { Alert, Box, Container, LinearProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { useAlert } from '../context/AlertContext';

const BlankLayout = () => {
  const { currentUser, loading } = useAuthContext();
  const { alerts } = useAlert();


  return (
    <Box sx={(theme) => ({
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    })}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, width: '100%' , px: "0px!important"}}>
        <Box display="flex" flexDirection="column" minHeight="100vh" sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}>
          <Navbar user={currentUser} loading={loading} />
          <Container maxWidth="xl" sx={(theme) => ({ flexGrow: 1 , pl: '0px!important', pr: '0px!important'})}>
            {loading && <LinearProgress />}
            {alerts.map((alert, index) => (
              <Alert key={index} severity={alert.type}>
                {alert.message}
              </Alert>
            ))}
            <Outlet />
          </Container>

          <Footer />
        </Box>
      </Container>
    </Box>
  );
};

export default BlankLayout;
