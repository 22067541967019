import { auth, db } from '../firebase';
import { Timestamp, addDoc, collection, doc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';



/**
 * Retrieves the document of the currently signed-in user.
 * @returns {Promise<Object>} The user document data.
 * @throws {Error} If no user is currently signed in or if the user document doesn't exist.
 */
export const getCurrentUserDocument = async () => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user currently signed in');
  }

  // Get the document for the current user
  const q = query(collection(db, "users"), where("uid", "==", user.uid));
  const docs = await getDocs(q);

  // TODO: I should create the user document if it doesn't exist
  if (docs.docs.length === 0) {
    console.log('no user document found for this user', user.uid);
    return null;
  }
  const userDoc = docs.docs[0];

  // If the user document doesn't exist, throw an error
  if (!userDoc.exists) {
    throw new Error(`No document found for user with UID: ${user.uid}`);
  }

  return userDoc;
};

/**
 * Retrieves the user document from the Firestore database based on the provided UID.
 * @param {string} uid - The UID of the user.
 * @returns {Promise<Object|null>} - A promise that resolves with the user document data if found, or null if not found.
 * @throws {Error} - Throws an error if the user document doesn't exist.
 */
export const getUserDocument = async (uid) => {
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const docs = await getDocs(q);


  if (docs.docs.length === 0) {
    console.log('no user document found for this user', uid);
    return null;
  }

  const userDoc = docs.docs[0];

  // If the user document doesn't exist, throw an error
  if (!userDoc.exists) {
    console.log('no user document found for this user', uid)
    throw new Error(`No document found for user with UID: ${uid}`)
  }

  return userDoc;
};

/**
 * Generates a user document in firestore from a Firebase user object.
 * @param {Object} user - The Firebase user object.
 * @param {Object} additionalData - Additional data to be included in the user document.
 * @returns {Promise<Object>} - A promise that resolves to the user document reference.
 * @throws {Error} - If no user is found or if a user document already exists for the user.
 */
export const getOrGenerateUserDocumentFromFirebaseUser = async (user, additionalData) => {
  console.log("generateUserDocumentFromFirebaseUser : user is : %o" , user, additionalData);
  if (!user || !user.uid) {
    console.log('No user found: uid: %s',user?.uid);
    throw new Error('No user found');
  }
  const q = query(collection(db, "users"), where("uid", "==", user.uid));
  const docs = await getDocs(q);
  if (docs.docs.length > 0 && docs.docs[0].exists()) {
    console.log('user document already found for this user', user.uid);
    return docs.docs[0];
  }
  const userDocRef = await setDoc(doc(db, "users", user.uid), {
    uid: user.uid,
    email: user.email,
    name: user.displayName || '',
    photoURL: user.photoURL,
    createdAt: serverTimestamp(),
    ...additionalData,
  });
  if(userDocRef?.getDocs()?.docs?.length>0)
    return userDocRef.docs[0];
  else
    return null;
}


/**
 * Fetches FitBit users from the database.
 * @returns {Promise<Array<Object>>} An array of FitBit user data.
 */
export const fetchFitBitUsers = async () => {
  const q = query(collection(db, "users"), where("fitbitData.access_token", "!=", null));
  const docs = await getDocs(q);
  return docs.docs.map(doc => doc.data());
}

export const setFitbitAuthData = async (userId, fitbitData) => {
  if (!userId || !fitbitData?.access_token) {
    throw new Error('userId and fitbitData are required');
  }
  const res = await updateDoc(doc(db, "users", userId), {
    "fitbitData": {...fitbitData , timestamp: Timestamp.now()},
    "fitbitRequest.challenge": null,
    "fitbitRequest.state": null
  });
  return res;
}

/**
 * Completes the registration process for a user.
 *
 * @param {Object} user - The user object from Firebase Auth.
 * @returns {Object} - The invitee document data.
 * @throws {Error} - If no user document or invitee document is found.
 */
export const completeRegsistration = async ( user) => {
  const userDoc = await getUserDocument(user.uid);
  if (userDoc === null) {
    throw new Error(`No user document found for UID: ${user.uid}`);
  }
  await updateDoc(userDoc.ref, {
    registrationStep: 5,
    registration: 'completed',
    registrationCompletedAt: Timestamp.now()
  });

  const inviteeDoc = await getInviteeDocumentByUser(userDoc.data());
  if (inviteeDoc === null) {
    throw new Error(`No invitee document found for userId: ${userDoc.data().uid}`);
  }
  await updateDoc(inviteeDoc.ref, {
    completed: true,
    completedAt: Timestamp.now(),
    registrationStep: 5,
    jamaspUserID: userDoc.id
  });

  return inviteeDoc.data();;
}

/**
 * Retrieves the invitee document associated with the given user, firestore user object.
 * @param {Object} user - The user object.
 * @returns {Promise<DocumentSnapshot|null>} - The invitee document or null if not found.
 * @throws {Error} - If no document found for the invitee with the given UID.
 */
const getInviteeDocumentByUser = async (user) => {
  const q = query(collection(db, "invitees"), where("email", "==", user.email));
  const docs = await getDocs(q);
  if (docs.docs.length === 0) {
    console.log('no invitee document found for this user', user.email);
    return null;
  }
  const inviteeDoc = docs.docs[0];
  if(!inviteeDoc.exists){
    console.log('no invitee document found for this user', user.email);
    throw new Error(`No document found for invitee with UID: ${user.email}`)
  }
  return inviteeDoc;
}

/**
 * Retrieves the invitee document from the "invitees" collection based on the provided UID.
 *
 * @param {string} uid - The UID of the invitee.
 * @returns {Promise<DocumentSnapshot>} - A promise that resolves to the invitee document if found, or null if not found.
 * @throws {Error} - Throws an error if the invitee document is not found.
 */
export const getInviteeDocument = async (invitationID) => {
  const q = query(collection(db, "invitees"), where("invitationID", "==", invitationID));
  const docs = await getDocs(q);

  if (docs.docs.length === 0) {
    console.log('no invitee document found for this user', invitationID);
    return null;
  }
  const inviteeDoc = docs.docs[0];
  if(!inviteeDoc.exists){
    console.log('no invitee document found for this user', invitationID);
    throw new Error(`No document found for invitee with UID: ${invitationID}`)
  }
  return inviteeDoc;
}

// TODO: Add more functions here to interact with Firestore and Firebase Auth
export const checkUserExistsAndPhoneIsNotVerified = (user) => {
  //todo check the firebase too.
  //console.log(" checkUserExistsAndPhoneIsNotVerified , user is " + user);
  if (!!user || !user?.uid) {
    return false;
  }
  if (user?.uid) {
    if (user?.phoneNumberVerified)
      return true;
    else
      return false;
  }
}
// TODO: Move this to functions
// This one is for the admin to check if the user is banned or not
// The exact strategy is still not clear
// For now, we will just check if the user exists in our registered users
export const updateGlobalInvitees = async (project, projectID) => {
  // console.log("updateGlobalInvitees , project is " + project);
  // console.log("updateGlobalInvitees , projectID is " + projectID);
  try {
    let existingInvitationIDs = [];
    let newInvitees = [];
    if (project?.invitedNewUsers === undefined || project?.invitedNewUsers === null || project?.invitedNewUsers.length === 0) {
    } else {
      const q = query(collection(db, "invitees"), where("invitationID", "!=", null));
      const allInvitees = await getDocs(q);
      existingInvitationIDs = allInvitees.docs.map(doc => doc.data().invitationID);

      newInvitees = project.invitedNewUsers.filter(invitee => !existingInvitationIDs.includes(invitee.invitationID));
    }

    // Add new invitees to the invitees collection
    for (const invitee of newInvitees) {
      const inviteeInstance = createNewUserInvitee(invitee, projectID);
      await addDoc(collection(db, "invitees"), inviteeInstance);
    }

    return newInvitees;
  } catch (error) {
    console.error("Error updating global invitees:", error);
    throw error;
  } finally {
    // Perform cleanup or final actions here
  }
}

/**
 * Creates a new project invitee object.
 *
 * @param {string} uid - The unique identifier of the invitee.
 * @param {string} name - The name of the invitee.
 * @param {string} email - The email of the invitee.
 * @param {string} phone - The phone number of the invitee.
 * @param {string} role - The role of the invitee.
 * @returns {Object} - The newly created project invitee object.
 */
export const createNewProjectInvitee =
  ({uid, name, email, phone, role}) => (
    {
      name: name,
      uid: uid,
      email: email,
      phone: phone,
      role: role,
      invitationID: generateInviteCode(),
      invitationStatus: "initiating"
  });

/**
 * Creates a new user invitee instance for the /invitees collection.
 *
 * @param {Object} invitee - The invitee object.
 * @param {string} projectId - The ID of the project.
 * @returns {Object} - The invitee instance.
 */
export const createNewUserInvitee = (invitee, projectId) => {
  const inviteeInstance = {
    name: invitee.name,
    uid: invitee.uid,
    email: invitee.email,
    phone: invitee.phone,
    role: invitee.role,
    inviteStatus: "initiated",
    phoneVerified: false,
    projectID: projectId,
    inviteStatusUpdatedAt: Timestamp.now(),
    invitationID: invitee.invitationID,
    invitationStatus: "initiating",
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    createdBy: auth.currentUser.uid,
    jamaspUserID: null,
    firebaseUserID: null,
    invitationSentAt: null,
    registrationStep: -1, // 0:intro 1:edit 2: google, 3: phone verification, 4: fitbit
    registrationStepUpdatedAt: null
  }
  return inviteeInstance;
}

/**
 * Returns whether the current user's phone number is verified or not.
 *
 * This function checks if the user's phone number is verified in three places:
 * - The provider data of the current user (which is where Firebase stores phone number verification info).
 * - The phoneVerified field of the user document in the users collection in Firestore.
 * - The invitees collection in Firestore (which is where we store the phone number verification status of newly registered users).
 *
 * @returns {boolean} - Whether the current user's phone number is verified or not.
 */
export const getCurrentUserPhoneVerified = () => {
  const user = auth.currentUser;
  if (!user || !user?.uid) {
    return false;
  }

  // Check if the phone number is verified in the provider data of the current user.
  const phoneVerifiedInProviderData = user.providerData.find(predicate =>
    predicate?.providerId === "phone" && predicate?.phoneNumber?.length > 0);
  if (phoneVerifiedInProviderData) {
    return true;
  }

  // Check if the phone number is verified in the user document in the users collection in Firestore.
  const docSnapshot = doc(collection(db, "users"), user.uid).get();
  if (docSnapshot.then(doc => doc?.data()?.phoneVerified === true)) {
    return true;
  }

  // Check if the phone number is verified in the invitees collection in Firestore.
  const inviteeDocSnapshot = collection(db, "invitees").where("uid", "==", user.uid).get();
  if (inviteeDocSnapshot.then(querySnapshot => querySnapshot.docs[0]?.data()?.phoneVerified === true)) {
    return true;
  }

  return false;

}


/**
 * Generates a random invite code.
 *
 * @return {string} The randomly generated invite code.
 */
export const generateInviteCode = () => {
  return Math.random().toString(36).substring(2, 12).toUpperCase();
}

/**
 * Generates a unique participant UID.
 *
 * @return {string} The unique participant UID generated.
 */
export const generateParticipantUID = () => {
  return "uid-" + Math.random().toString(36).substring(2, 12).toUpperCase();
}

/**
 * Generates action code settings for registration based on the provided invitation ID.
 *
 * @param {string} invitationID - The ID of the invitation used to customize the registration URL.
 * @return {object} The action code settings object containing the URL and handleCodeInApp flag.
 */
export const generateRegistrationActionCodeSettings = (invitationID) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://jamasp.app/auth/register/'+invitationID,
    // This must be true.
    handleCodeInApp: true,
  };
  return actionCodeSettings;
}
