import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJkfaWrYjRbocOuU1Lp6sLr3V2ZaCGc4E",
  authDomain: "jamasp-gcp-project.firebaseapp.com",
  projectId: "jamasp-gcp-project",
  storageBucket: "jamasp-gcp-project.appspot.com",
  messagingSenderId: "730427234084",
  appId: "1:730427234084:web:f5767f4b7618e5d24745b1",
  measurementId: "G-736SS41DRJ"
};

// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID
// };


// const adminFirebaseConfig = {
//   apiKey: "AIzaSyDJkfaWrYjRbocOuU1Lp6sLr3V2ZaCGc4E",
//   authDomain: "jamasp-gcp-project.web.app",
//   projectId: "jamasp-gcp-project",
//   storageBucket: "jamasp-gcp-project.appspot.com",
//   messagingSenderId: "730427234084",
//   appId: "1:730427234084:web:3723e03db46fe1404745b1",
//   measurementId: "G-FLJNN8B9EN"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
if (window.location.hostname === "localhost") {
  // connectAuthEmulator(auth, "http://localhost:9099");
}
const db = getFirestore(app);
const analytics = getAnalytics(app);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
const functions = getFunctions(app);
const storage = getStorage();
const fitbitBucket = getStorage(app, "gs://jamasp-fitbit");
if (window.location.hostname === "localhost") {
  // connectFunctionsEmulator(functions, "localhost", 5001);
}

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);

//const messaging = app.messaging();
// const adminApp = initializeAdminApp(adminFirebaseConfig);
// const adminAuth = adminApp.auth();

const googleProvider = new GoogleAuthProvider();
//const phoneProvider = new PhoneAuthProvider(auth);

// function recaptchaVerifierInvisible() {
//   function onSignInSubmit() {
//     // TODO(you): Implement
//     console.log('onSignInSubmit');

//   }

//   // [START auth_phone_recaptcha_verifier_invisible]
//   window.recaptchaVerifier = auth.RecaptchaVerifier('sign-in-button', {
//     'size': 'invisible',
//     'callback': (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       onSignInSubmit();
//     },
//     'expired-callback': () => {
//       // Response expired. Ask user to solve reCAPTCHA again.
//       // ...
//     }
//   });

// }
// function recaptchaVerifierVisible() {
//   // [START auth_phone_recaptcha_verifier_visible]
//   window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
//     'size': 'normal',
//     'callback': (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       // ...
//     },
//     'expired-callback': () => {
//       // Response expired. Ask user to solve reCAPTCHA again.
//       // ...
//     }
//   });
//   // [END auth_phone_recaptcha_verifier_visible]
// }

// function recaptchaVerifierSimple() {
//   // [START auth_phone_recaptcha_verifier_simple]
//   window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container');
//   // [END auth_phone_recaptcha_verifier_simple]
// }

// function recaptchaRender() {
//   /** @type {firebase.auth.RecaptchaVerifier} */
//   const recaptchaVerifier = window.recaptchaVerifier;

//   // [START auth_phone_recaptcha_render]
//   recaptchaVerifier.render().then((widgetId) => {
//     window.recaptchaWidgetId = widgetId;
//   });
//   // [END auth_phone_recaptcha_render]
// }

// function phoneSignIn() {
//   function getPhoneNumberFromUserInput() {
//     return "+15558675309";
//   }

//   // [START auth_phone_signin]
//   const phoneNumber = getPhoneNumberFromUserInput();
//   const appVerifier = window.recaptchaVerifier;
//   auth.signInWithPhoneNumber(phoneNumber, appVerifier)
//       .then((confirmationResult) => {
//         // SMS sent. Prompt user to type the code from the message, then sign the
//         // user in with confirmationResult.confirm(code).
//         window.confirmationResult = confirmationResult;
//         // ...
//       }).catch((error) => {
//         // Error; SMS not sent
//         // ...
//       });
//   // [END auth_phone_signin]
// }

// function verifyCode() {
//   function getCodeFromUserInput() {
//     return "1234";
//   }

//   /** @type {firebase.auth.ConfirmationResult} */
//   const confirmationResult = undefined;

//   // [START auth_phone_verify_code]
//   const code = getCodeFromUserInput();
//   confirmationResult.confirm(code).then((result) => {
//     // User signed in successfully.
//     const user = result.user;
//     // ...
//   }).catch((error) => {
//     // User couldn't sign in (bad verification code?)
//     // ...
//   });
//   // [END auth_phone_verify_code]
// }

// function getRecaptchaResponse() {
//   const recaptchaWidgetId = "...";
//   const grecaptcha = {};

//   // [START auth_get_recaptcha_response]
//   const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId);
//   // [END auth_get_recaptcha_response]
// }

// const logout = () => {
//   signOut(auth).then(() => {
//     // Do the sign out stuff here
//     //TODO: check if here is a good place for doing this
//   }).catch((error) => {
//     console.log(error);
//     //todo: setup alerts
//     //todo: log with firebase
//   });
// };

export {
  analytics, app, auth, db, fitbitBucket, functions, googleProvider, perf,
  remoteConfig, storage
};

