import { Alert, Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from '../components/layout/Footer';
import { useAlert } from '../context/AlertContext';
const AuthLayout = () => {
    const { alerts } = useAlert();
    //const { loading } = useAuthContext();

    return (
        <Container component="main" maxWidth="sm">
            <Box minHeight="100vh" display="flex" flexDirection="column" >
                <Container sx={{ flexGrow: 1 }} >
                    {/* {loading && <LinearProgress color="secondary" />} */}
                    {alerts.map((alert, index) => (
                        <Alert key={index} severity={alert.type}>
                            {alert.message}
                        </Alert>
                    ))}

                    <Outlet />
                </Container>

                <Footer />
            </Box>
        </Container>


    );
};

export default AuthLayout;
