import { Box, Container, Paper, Typography } from "@mui/material";
import { useRegistration } from "../../context/RegistrationContext";

const RegistrationReviewStep = () => {

  const { inviteeData } = useRegistration();

  return (
    <Paper variant="outlined"
      sx={{
        my: { xs: 1, md: 6 }, p: { xs: 2, md: 3 }, display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography variant="h4" align="center" gutterBottom>
        Welcome!
      </Typography>
      <Container>
        <Typography variant="body1" align="justify" gutterBottom>
          Hi! Thanks for joining this research project.
        </Typography>
        <Typography variant="body1" align="justify" gutterBottom>
          Please follow the next steps to complete the registration process.
        </Typography>
        <Typography variant="body1" align="justify" gutterBottom>
          You previously provided us with the following contact information.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">Email: <b>{inviteeData.email}</b> </Typography>
        <Typography variant="subtitle1" color="text.secondary">Phone: <b>{inviteeData.phone}</b> </Typography>

        <Typography variant="body1" align="justify" gutterBottom>
          Please ensure this is accurate. You can modify it in the next step
        </Typography>
        <Typography variant="body1" align="justify" gutterBottom>
          Press <b>Next</b> to continue.
        </Typography>

      </Container>


    </Paper>
  );
}
export default RegistrationReviewStep;