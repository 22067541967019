import { Backdrop, Box, CircularProgress, LinearProgress, Stack, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { useAuthContext } from '../../auth/AuthContext';
import EmailLinkSignUpStep from './EmailLinkSignUpStep';
import PhoneVerifyStep from './PhoneVerifyStep';
import RegistrationReviewStep from './RegistrationReviewStep';
import SignUpStep from './SignUpStep';
import { useNavigate } from 'react-router-dom';
import FitbitAuthenticationStep from './FitbitAuthenticationStep';
import {
  Preview as PreviewIcon,
  Google as GoogleIcon,
  Phone as PhoneIcon,
  Watch as WatchIcon,
  ChevronLeft as BackIcon,
  ChevronRight as NextIcon
} from '@mui/icons-material/';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useRegistration } from '../../context/RegistrationContext';
import { useAlert } from '../../context/AlertContext';
import { LoadingButton } from '@mui/lab';

// TODO: Add a better validation for each step
const steps = ['Welcome!', 'Google Login', 'Verify Phone', 'Fitbit Access'];

/**
 * RegistrationStepper component for the registration process.
 * 
 * @param {Object} props - The component props.
 * @returns {ReactNode} - The RegistrationStepper component.
 */
const RegistrationStepper = (props) => {
  const { emailAuthMethod } = props;

  const { activeStep, inviteeData, nextStep, previousStep, error,
      localLoading, setLocalLoading, loading, currentUser, success } = useRegistration();

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  /** 
   * Debugging useEffect
   */
  useEffect(() => {
    console.log("RegistrationStepper: (%s) , inviteeData : %o", activeStep, inviteeData);

  }, [activeStep, inviteeData]);


  const isStepFailed = (step) => {
    return step === 2 && error;
  };

  /**
   * Returns the content for a specific step in the registration process.
   * @param {number} step - The current step in the registration process.
   * @returns {ReactNode} - The content for the specified step.
   * @throws {Error} - Throws an error if the step is unknown.
   */
  function getStepContent(step) {
    if(step===null || step===undefined){
      console.log("getStepContent: step is null");
      return;
    }
    console.log("getting step : %s", step);
    switch (step) {
      case 0:
        return <RegistrationReviewStep />;
      case 1:
        return (emailAuthMethod === "EMAIL_LINK")
          ?
          <EmailLinkSignUpStep />
          :
          <SignUpStep />;
      //return <SignUpStep signUpWithGoogle={signUpWithGoogle} onCompleted={handleNext}  />;
      // return <EmailLinkSignUpStep inviteeData={inviteeData} userData={userData} loading={loading} setLoading={setLoading} error={error} setError={setError} success={success} setSuccess={setSuccess} signUpWithEmailLink={signUpWithEmailLink} onCompleted={handleNext} invitationID={invitationID} />;
      case 2:
        return <PhoneVerifyStep />;
      case 3:
        return <FitbitAuthenticationStep />;
      default:
        throw new Error('Unknown step');
    }
  }

  

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4">Registration Wizard</Typography>
      <Stepper sx={{ pt: 2, pb: 1 }} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
        {steps.map((label, index) => {
          const labelProps = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Alert message
              </Typography>
            );

            labelProps.error = true;
          }
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {/* Step content */}
        {(loading || localLoading) && (
          // Show a loading indicator while the user checks if they are logged in
          <Fragment>
            <Backdrop
              sx={{ color: '#fff' }}
              open={loading || localLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <LinearProgress />
          </Fragment>
        )}
        { activeStep!==null && activeStep!==undefined && activeStep <= 4 && (
          <>{getStepContent(activeStep)}</>
        )}
        {/* Navigation buttons */}
        <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
          {activeStep !== 0 && activeStep < 2 && (
            <LoadingButton
              loading={localLoading}
              disabled={loading || localLoading}
              onClick={() => {
                setLocalLoading(true);
                previousStep();
                setLocalLoading(false);
              }}
              loadingPosition="start"
              startIcon={<BackIcon />}
              variant="outlined"
              color="secondary"
            >
              Back
            </LoadingButton>
          )}
          {activeStep === 0 && (
            <LoadingButton
              loading={localLoading}
              disabled={loading || localLoading}
              onClick={() => {
                setLocalLoading(true);
                nextStep({});
                setLocalLoading(false);
              }}
              loadingPosition="start"
              startIcon={<NextIcon />}
              variant="outlined"
              color="primary"
            >
              Next
            </LoadingButton>
          )}
        </Stack>

      </div>
    </Box>
  );
}

RegistrationStepper.propTypes = {
  emailAuthMethod: PropTypes.string
};

export default RegistrationStepper;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(130, 127, 208) 0%, rgb(160, 148, 255) 50%, rgb(173, 190, 251) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(12, 184, 141) 0%, rgb(1, 90, 67) 50%, rgb(0, 70, 90) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PreviewIcon />,
    2: <GoogleIcon />,
    3: <PhoneIcon />,
    4: <WatchIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};