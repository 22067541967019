import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function ConfirmOptoutDialog({handleClose, withButton, buttonText, buttonColor, ...props}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = (selectValue) => {
    //console.log("handleClose", selectValue);
    if(selectValue!==undefined && selectValue!==null && selectValue!==false){
      // callback to the parent component
      handleClose(selectValue);
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      {withButton && (  
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" color={buttonColor ? buttonColor : "error"} onClick={handleClickOpen}>
            {buttonText ? buttonText : "Opt Out"}
          </Button>
        </Box>        
      )}
      {!withButton && (
        {...props}
      )}
      <Dialog
        open={open}
        onClose={() => handleCloseDialog(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to opt out of research?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to opt out of research? This will remove your data from the study and you will no longer be able to participate. 
            <br />
            You can NOT undo this action.
            <br />
            If you are sure you want to opt out, click Agree.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)}>Disagree</Button>
          <Button onClick={() => handleCloseDialog(true)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
