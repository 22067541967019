import { Info } from '@mui/icons-material';
import { Checkbox, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchFitBitUsers } from '../utils/firebase/users';
import { useNavigate } from 'react-router-dom';

function DevicesPanel(props) {
  const { project, onUserInput, userDevices } = props;
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFitBitUsers().then((users) => {
      setUsers(users);
    }).catch((error) => {
      console.log(error);
    });
  }, []);


  // handle checkbox change
  const handleCheckboxChange = (user, isChecked) => {
    onUserInput(user, isChecked);
  };

  const handleShowParticipantInfo = (userId) => {
    navigate(`/admin/users/${userId}`);
  }

  return (
    <List>
      {users?.map((user, index) => {
        const value = index;
        const labelId = `device-list-label-${value}`;

        return (
          <ListItem
            key={value}
            secondaryAction={
              <IconButton edge="end" aria-label="info" onClick={()=>handleShowParticipantInfo(user?.fitbitData?.user_id)}>
                <Info />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton role={undefined}
              //onClick={handleToggle(value)}
              onChange={(e) => handleCheckboxChange(user?.fitbitData?.user_id, e.target.checked)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={userDevices.includes(user?.fitbitData?.user_id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${user.name} | ${user?.fitbitData?.user_id}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {/* <FormGroup>
        {users?.map((user, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={userDevices.includes(user?.fitbitData?.user_id)}
                onChange={(e) => handleCheckboxChange(user?.fitbitData?.user_id, e.target.checked)}
              />
            }
            label={`${user.name} | ${user?.fitbitData?.user_id}`}
          />
        ))}
      </FormGroup> */}

    </List>


  )
}

export default DevicesPanel;
