import {
  Box, Button,
  Container,
  LinearProgress,
  Stack, Tab, Tabs, Typography
} from '@mui/material';
import { Timestamp, collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { trace } from 'firebase/performance';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../auth/AuthContext';
import { useAlert } from '../../context/AlertContext';
import { db, perf } from '../../firebase';
import { checkAllTrue } from '../../utils/utils';
import CronJobPanel from '../components/CronJobPanel';
import DataSettingsPanel from '../components/DataSettingsPanel';
import DevicesPanel from '../components/DevicesPanel';
import DownloadHistoryPanel from '../components/DownloadHistoryPanel';
import DownloadPanel from '../components/DownloadPanel';
import { ProjectTabPanel } from '../components/ProjectTabPanel';
import { cronJobSensors, generateCronSensorSettings } from '../data/sensorsCronJob';
import { defaultRangeDownloadSettings, generateSensorSettings, downloadSensors as sensorsList } from '../data/sensorsDownload';

/**
 * Renders the ShowProject component.
 * This component displays project details, allows user input for devices, sensors, and settings,
 * and provides options to save the project and download it as a JSON file.
 *
 * @returns {JSX.Element} The rendered ShowProject component.
 */
const ShowProject = () => {
  // Tab value which is used to display the correct tab
  const [tabValue, setTabValue] = useState(0);
  // Get projectId from the URL
  const { projectId } = useParams();
  // Project working with
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // Devices selected for the project to fetch data from
  const [userDevices, setUserDevices] = useState([]);
  // Sensors selected for the project to fetch data from fitbit
  // Sensors selected for the manual fetch job of the project
  const [userSensors, setUserSensors] = useState([]);
  // Settings for the selected sensors for the manual fetch job
  const [userSettings, setUserSettings] = useState({});
  // Sensors selected for the project fetch data automatically
  const [cronSensorsName, setCronSensorsName] = React.useState([]);
  // Settings for the selected sensors to fetch data automatically
  const [cronSensorsSettings, setCronSensorsSettings] = React.useState([]);
  // Sensors selected for the project to download data from fitbit
  const [sensorsName, setSensorsName] = React.useState([]);
  // Settings for the selected sensors for the download job
  const [sensorsSettings, setSensorsSettings] = React.useState([]);
  // Jobs fetched from the fetchJobs collection
  const [fetchJobsHistory, setFetchJobsHistory] = React.useState([]);
  // Range download settings for the project
  const [rangeDownloadSettings, setRangeDownloadSettings] = React.useState({});

  // Api responses fetched from fitbit
  // const [apiResponse] = useState(null);
  // User object from the AuthContext set in App.js
  const { currentUser, userJamasp, loading } = useAuthContext();

  // navigation hook
  const navigate = useNavigate();
  // alert context
  const { addAlert } = useAlert();

  /**
   * updates cron job and download fetch job sensors settings object only if sensors where
   * selected or deselected in download tab and cron tab
   */
  useEffect(() => {
    // setting the sensors settings for the download job
    // finds the sensors that are selected from sensorsSettings and filters them out
    let newSensorsSettings = sensorsSettings?.map((s) => ({ ...s, enabled: sensorsName.indexOf(s.sensorId) > -1 }));
    sensorsName?.forEach((sensorId) => {
      if (newSensorsSettings?.findIndex((s) => s.sensorId === sensorId) === -1) {
        newSensorsSettings.push(generateSensorSettings(sensorsList.find((s) => s.id === sensorId)));
      }
    });
    setSensorsSettings(newSensorsSettings);

    // setting the sensors settings for the cron job
    // finds the sensors that are selected from cronSensorsSettings and filters them out
    let newCronSensorsSettings = cronSensorsSettings?.map((s) => ({ ...s, enabled: cronSensorsName.indexOf(s.sensorId) > -1 }));
    cronSensorsName?.forEach((sensorId) => {
      if (newCronSensorsSettings?.findIndex((s) => s.sensorId === sensorId) === -1) {
        newCronSensorsSettings.push(generateCronSensorSettings(cronJobSensors.find((s) => s.id === sensorId)));
      }
    });
    setCronSensorsSettings(newCronSensorsSettings);
  }, [sensorsName, cronSensorsName]);

  /**
   * Retrieves project data from Firestore and updates the state on startup.
   *
   * @return {void}
   */
  useEffect(() => {
    setIsLoading(true);
    const query = doc(db, "projects", projectId);

    const unsubscribe = onSnapshot(query, (doc) => {
      if (doc.exists()) {
        // TODO: Create custom trace to monitor page loading as an excersice.
        const t = trace(perf, "setting project data");
        t.putAttribute("projectId", projectId);
        t.start();

        setProject(doc.data());
        //console.log('project found:', doc.data());
        setUserDevices(doc.data().devices);
        setUserSensors(doc.data().sensors);
        setUserSettings(doc.data().settings);
        if (!!doc.data()?.downloadSettings) {
          setSensorsSettings(doc.data()?.downloadSettings);
          setSensorsName(doc.data()?.downloadSettings?.filter((s) => s.enabled).map((s) => s.sensorId));
        }
        if (!!doc.data()?.rangeDownloadSettings) {
          setRangeDownloadSettings(doc.data()?.rangeDownloadSettings);
        }else{ // we don't have range download settings, set the default
          setRangeDownloadSettings(defaultRangeDownloadSettings);
        }
        if ((!!doc.data()?.cronSettings)) {
          setCronSensorsSettings(doc.data()?.cronSettings);
          setCronSensorsName(doc.data()?.cronSettings?.filter((s) => s.enabled).map((s) => s.sensorId));
        }
        updateFetchJobsHistory(projectId);
        t.stop();
        //console.log(userDevices, userSensors, userSettings);
      } else {
        addAlert('error', `Project with id: ${projectId} could not be found!`);
        setProject(null);

      }
    });
    setIsLoading(false);
    return unsubscribe;
  }, [projectId]);


  /**
   * Updates the fetch jobs history for a given project.
   * @param {Object} project - The project object.
   * @returns {Promise<void>} - A promise that resolves when the fetch jobs history is updated.
   */
  const updateFetchJobsHistory = async (projectId) => {
    const fetchJobsRef = collection(db, "fetchJobs");
    const querySnapshot = await getDocs(query(fetchJobsRef, where("projectId", "==", projectId), orderBy("startedAt", "asc")));
    let jobs = [];
    querySnapshot.forEach((doc) => {
      const fetchJobRef = doc.ref;
      const fetchJobHistory = doc?.data();
      if (!!fetchJobHistory?.outputFile) {
        const fetchJobHistoryProject = {
          ...fetchJobHistory,
          projectId: projectId ? projectId : "null",
          status: "done",
          startedAt: fetchJobHistory?.startedAt,
          fetchJobHistoryId: fetchJobRef?.id,
          outputFile: fetchJobHistory.outputFile,
        };
        jobs.push(fetchJobHistoryProject);
      }
    });
    setFetchJobsHistory(jobs);
  }

  /**
   * Handles the user devices input.
   * @param {string} name - The name of the device.
   * @param {boolean} checked - The checked state of the device.
   */
  const handleUserDevicesInput = (name, checked) => {
    setUserDevices(
      checked
        ? [...userDevices, name]
        : userDevices.filter((device) => device !== name)
    );
  }


  /**
   * Updates the user settings with the provided name and value.
   *
   * @param {string} name - The name of the setting to update.
   * @param {any} value - The new value for the setting.
   * @return {void}
  */
  const handleUserSettingsInput = (name, value) => {
    // Update the user settings object with the new name and value
    setUserSettings({
      ...userSettings,
      [name]: value,
    });
  }
  /**
   * Handles the date change event and updates the user settings.
   *
   * @param {string} name - The name of the date field.
   * @param {any} value - The new value for the date field.
   * @return {void}
   */
  const handleDateChange = (name, value) => {
    //console.log(name, value, value.type);
    setUserSettings({
      ...userSettings,
      dateRange: {
        ...userSettings.dateRange,
        [name]: value,
      },
    });
  }

  /**
   * handles the tab change event and updates the tab value.
   * @param {*} event  event.target.name = argument name
   * @param {*} newValue new tab value
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Handles saving the project by updating the project document with the new user devices, sensors, and settings.
   *
   * @return {void} No return value.
   */
  const handleSaveProject = () => {
    setIsLoading(true);
    try {
      // Update the project document with the new user devices, sensors, and settings
      const updatedProject = {
        ...project,
        devices: userDevices,
        sensors: userSensors,
        settings: userSettings,
        downloadSettings: sensorsSettings,
        cronSettings: cronSensorsSettings,
        rangeDownloadSettings: rangeDownloadSettings,
      }
      console.log("updatedProject: ", updatedProject);
      console.log("project: ", project);
      // Check if the project has been updated if not do not update
      // TODO: this method doesnt work as expected, check firebase docs for more info
      if (updatedProject === project) {
        console.log("No changes detected in the project");
        addAlert('error', `No changes detected in the project with id: ${projectId}`);
        setIsLoading(false);
        return;
      }
      updatedProject.updatedAt = Timestamp.now();
      if (updatedProject?.id === undefined) {
        updatedProject.id = projectId;
      }

      //console.log("updatedProject: ", updatedProject);
      //const projectRef = doc(db, "projects", projectId);
      updateDoc(doc(db, "projects", projectId), updatedProject).then(() => {
        setProject(updatedProject);
        addAlert('success', `Project ${updatedProject.name} with id: ${projectId} updated successfully`);
        console.log("Project updated successfully");
        setIsLoading(false);
      }).catch((error) => {
        addAlert('error', `Project ${updatedProject.name} with id: ${projectId} could not be updated!!!!`);
        console.error("Error updating project: ", error);
      })
    } catch (error) {
      addAlert('error', `Project with id: ${projectId} could not be updated!!!!`);
      console.error("Error updating project: ", error);
    }
    setIsLoading(false);
  }

  /**
   * Disable/Enables the project. It toggles the project status between enabled and disabled.
   * @returns {boolean} - Returns false to prevent the project from being disabled.
   */
  const handleToggleDisableProject = async () => {
    return false;
  }

  /**
   * Handle the download of the project data.
  */
  const handlePrepareDownload = async () => {
    // first save the project
    handleSaveProject();

    const cloudRunEndpoint = 'https://fetch-download-job-bsa7yb7sga-uc.a.run.app/fetchData';
    if (isLoading || loading) {
      addAlert('error', `Please wait for the previous download to finish.`);
    } else {
      setIsLoading(true);
      try {
        fetch(`${cloudRunEndpoint}?project_id=${projectId}`, {
          method: 'GET',
        }).then((response) => {
          console.log("response: ", response);
          if (response.ok) {
            addAlert('success', `Data fetched successfully for project with id: ${projectId}`);
            return response.json();
          } else {
            addAlert('error', `Error fetching data for project with id: ${projectId}`);
            return Promise.reject(response);
          }
        });
      } catch (err) {
        console.error("Error fetching data: ", err);
        addAlert('error', `Error fetching data for project with id: ${projectId}`);
      }
      setIsLoading(false);
    }
  }

  /**
   * Returns the accessibility properties for a tab.
   *
   * @param {number} index - The index of the tab.
   * @returns {Object} - The accessibility properties object.
   */
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  /**
   * Renders the project page.
   *
   * @returns {JSX.Element} - The rendered project page.
   */
  return (
    <Box>
      <Container maxWidth="xl" pb={2}>
        <Typography variant="h4" gutterBottom component="div" sx={{ my: 2 }}>{project?.name}</Typography>
        <Typography variant="subtitle2" gutterBottom >{project?.description}</Typography>

        {checkAllTrue(loading, isLoading) && <LinearProgress />}

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Participants" {...a11yProps(0)} />
            <Tab label="Auto Fetch" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
            <Tab label="Download" {...a11yProps(3)} />
            <Tab label="Download History" {...a11yProps(4)} />
            <Tab label="Insights and Analytics" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <ProjectTabPanel value={tabValue} index={0}>
          <DevicesPanel project={project} onUserInput={handleUserDevicesInput} userDevices={userDevices} />
        </ProjectTabPanel>
        <ProjectTabPanel value={tabValue} index={1}>
          <CronJobPanel project={project} setCronSensorsName={setCronSensorsName} cronSensorsName={cronSensorsName}
            cronSensorsSettings={cronSensorsSettings} setCronSensorsSettings={setCronSensorsSettings}
          />
        </ProjectTabPanel>
        <ProjectTabPanel value={tabValue} index={2}>
          <DataSettingsPanel project={project} onDateChange={handleDateChange} onUserInput={handleUserSettingsInput}
            userSettings={userSettings} />
        </ProjectTabPanel>
        <ProjectTabPanel value={tabValue} index={3}>
          <DownloadPanel project={project} setSensorsName={setSensorsName} sensorsName={sensorsName}
            sensorsSettings={sensorsSettings} setSensorsSettings={setSensorsSettings}
            handlePrepareDownload={handlePrepareDownload}
            rangeDownloadSettings={rangeDownloadSettings} setRangeDownloadSettings={setRangeDownloadSettings} />
        </ProjectTabPanel>
        <ProjectTabPanel value={tabValue} index={4}>
          <DownloadHistoryPanel project={project} fetchJobsHistory={fetchJobsHistory} />
        </ProjectTabPanel>



        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ pt: 1 , mb: 2 }}>
          <Button disabled={loading || isLoading} variant="contained" color="primary" onClick={handleSaveProject}>Save</Button>
          <Button disabled={loading || isLoading} variant="contained" color="error" onClick={handleToggleDisableProject}>Disable Project</Button>
        </Stack>
      </Container >

    </Box>

  );
};

export default ShowProject;
